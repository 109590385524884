import { useMemo, useCallback, useState } from 'react';
import forEach from 'lodash/forEach';
import keys from 'lodash/keys';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { ProductsElasticSearchSuggestInputSuggestType } from '../../ProductsElasticSearchSuggestInput.types';

import { useShowToastOnErrorChange } from '../../../../../../common/hooks/useShowToastOnErrorChange';
import { useProductsElasticSearchSuggest } from '../../../../hooks/useProductsElasticSearchSuggest';

import { ProductCache } from '../../../../ProductCache';

function getLabelOrder(label: string) {
  switch (label) {
    case 'tags':
      return 1;
    case 'name':
      return 2;
    case 'category':
      return 3;
    case 'brand':
      return 4;
    case 'sku':
      return 5;
    default:
      return 10;
  }
}

interface ProductsElasticSearchSuggestInputItemsOptions {
  initialQuery?: string;
}
function useProductsElasticSearchSuggestInputItems({
  initialQuery
}: ProductsElasticSearchSuggestInputItemsOptions) {
  const [enabled, setEnabled] = useState<boolean>(!!initialQuery);
  const {
    productsElasticSearchSuggest,
    productsElasticSearchSuggestErrorMessage,
    changeProductsElasticSearchSuggestParams
  } = useProductsElasticSearchSuggest({
    cacheKey: ProductCache.elasticSearchSuggestCacheKey(),
    initialParams: initialQuery ? { query: initialQuery } : {},
    options: {
      keepPreviousData: true,
      staleTime: 1000 * 60 * 60,
      enabledPlaceholder: false,
      enabled
    }
  });

  useShowToastOnErrorChange({
    error: productsElasticSearchSuggestErrorMessage
  });

  const popoverItems = useMemo<
    ProductsElasticSearchSuggestInputSuggestType[]
  >(() => {
    const items = [];

    const sortedLabels = sortBy(
      keys(productsElasticSearchSuggest),
      getLabelOrder
    );

    forEach(sortedLabels, (label) => {
      const values = productsElasticSearchSuggest[label];
      if (isEmpty(values)) {
        return;
      }
      forEach(values, (value) => items.push({ value, label }));
    });

    return items;
  }, [productsElasticSearchSuggest]);

  const makeSuggest = useCallback<(newValue: string) => void>(
    (newValue) => {
      changeProductsElasticSearchSuggestParams(
        newValue ? { query: newValue } : {}
      );

      setEnabled(!!newValue);
    },
    [changeProductsElasticSearchSuggestParams]
  );

  return {
    popoverItems,
    makeSuggest
  };
}

export default useProductsElasticSearchSuggestInputItems;
