import React, { useCallback, useMemo } from 'react';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { Translate } from '../../../../../helpers/Translate';
import {
  YesNoAnyRadioButtons,
  YesNoAnyRadioButtonsValue
} from '../../../../../helpers/YesNoAnyRadioButtons';

import { formsFields } from '../../../../../locales/keys';

interface ProductsBlockedFilterProps {
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'blocked' as const;

function ProductsBlockedFilter({
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsBlockedFilterProps) {
  const handleChange = useCallback<(value: YesNoAnyRadioButtonsValue) => void>(
    (value) => {
      switch (value) {
        case YesNoAnyRadioButtonsValue.YES:
          changeProductsFilters({ [name]: true });
          break;
        case YesNoAnyRadioButtonsValue.NO:
          changeProductsFilters({ [name]: false });
          break;
        case YesNoAnyRadioButtonsValue.ANY:
          changeProductsFilters({}, [name]);
          break;
      }
    },
    [changeProductsFilters]
  );

  const value = useMemo(() => {
    switch (productsFilters?.[name]) {
      case true:
        return YesNoAnyRadioButtonsValue.YES;
      case false:
        return YesNoAnyRadioButtonsValue.NO;
      default:
        return YesNoAnyRadioButtonsValue.ANY;
    }
  }, [productsFilters]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={formsFields.blocked} />
        </h6>
      </div>

      <YesNoAnyRadioButtons
        dataGa="products-blocked-filter"
        name={name}
        value={value}
        disabled={disabled}
        onChange={handleChange}
      />
    </div>
  );
}

export default ProductsBlockedFilter;
