import { useState } from 'react';
import { useQuery } from 'react-query';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';

import { LocalForage } from '../../../../../../utils/LocalForage';

import { FetchItemCacheKey, FetchItemUrl } from '../../../../../../types';

import { BffApiRequest } from '../../../../../../utils/BffApiRequest';
import { parseRequestError } from '../../../../../../utils/parseRequestError';

import {
  BffGetQueryResponse,
  BffGetQueryOpts,
  BffGetQueryErrorType,
  BffGetQueryBaseParamsType
} from './useBffGetQuery.types';

interface BffGetQueryOptions<ParamsType> {
  cacheKey: FetchItemCacheKey;
  scope: string;
  url: FetchItemUrl;
  initialParams?: ParamsType;
  options?: BffGetQueryOpts;
}

function useBffGetQuery<
  DataType,
  ParamsType extends BffGetQueryBaseParamsType
>({
  cacheKey,
  scope,
  url,
  initialParams,
  options = {}
}: BffGetQueryOptions<ParamsType>) {
  const [currentParams, setCurrentParams] = useState<ParamsType>(initialParams);

  const localForageCacheKey = `${cacheKey}-get`;

  const {
    data: localForagePlaceholderData,
    isFetched: placeholderDataFetched
  } = useQuery<BffGetQueryResponse<DataType> | null>(
    `${localForageCacheKey}-placeholder`,
    () =>
      LocalForage.getItem<BffGetQueryResponse<DataType>>(localForageCacheKey),
    {
      enabled: options.enabledPlaceholder
    }
  );

  const { data, isFetched, isLoading, isFetching, error, isPlaceholderData } =
    useQuery<BffGetQueryResponse<DataType>, BffGetQueryErrorType>(
      [cacheKey, currentParams],
      async () => {
        try {
          const response = await BffApiRequest.getNonStandard<
            BffGetQueryResponse<DataType>
          >(url, currentParams);

          return response.data;
        } catch (err) {
          throw isEmpty(err?.response?.data?.error?.fullMessages)
            ? err
            : err?.response?.data?.error;
        }
      },
      {
        cacheTime: options.cacheTime,
        enabled: options.enabled || placeholderDataFetched,
        keepPreviousData: options.keepPreviousData,
        onSuccess: (data) => {
          if (isEqual(currentParams, initialParams)) {
            return LocalForage.setItem<BffGetQueryResponse<DataType>>(
              localForageCacheKey,
              data
            );
          }
        },
        placeholderData: () => {
          if (
            localForagePlaceholderData &&
            isEqual(currentParams, initialParams)
          ) {
            return localForagePlaceholderData;
          }
        }
      }
    );

  const responseData: DataType | null = data?.[scope] || null;

  return {
    responseData,
    errorData: error,
    error: parseRequestError(error),
    isFetched,
    isFetching,
    isLoading,
    isPlaceholderData,
    currentParams,
    changeParams: setCurrentParams
  };
}

export default useBffGetQuery;
