import {
  INITIAL_PRODUCT_SEASONAL_FILTERS,
  INITIAL_PRODUCT_SEASONAL_LIMIT,
  INITIAL_PRODUCT_SEASONAL_SORT
} from '../../productsConstants';
import {
  FetchFinProductCategoriesFilters,
  FetchProductCategoriesCacheKey,
  FetchProductCategoriesLimit,
  FetchProductCategoriesSort
} from '../../productsTypes';
import { FETCH_CACHE_PRODUCT_SEASONAL_QUERY } from '../../queries/fetchCacheProductSeasonal.query';
import {
  FETCH_PRODUCT_SEASONAL_QUERY,
  FetchProductSeasonalQueryResponse
} from '../../queries/fetchProductSeasonal.query';

import { useCacheInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useCacheInfiniteIndexQuery';

import { CategoriesPermissions } from '../../../categories/categoriesConstants';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductSeasonalDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  initialFilters?: FetchFinProductCategoriesFilters;
  initialSort?: FetchProductCategoriesSort;
  initialLimit?: FetchProductCategoriesLimit;
  options?: {
    staleTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

function useFinProductSeasonal({
  cacheKey,
  initialFilters = INITIAL_PRODUCT_SEASONAL_FILTERS,
  initialSort = INITIAL_PRODUCT_SEASONAL_SORT,
  initialLimit = INITIAL_PRODUCT_SEASONAL_LIMIT
}: ProductSeasonalDefaultOptions) {
  const {
    items,
    itemsError,
    isFetched,
    isPlaceholderData,
    isFetchingNextPage,
    currentFilters,
    hasNextPage,
    loadMoreItems,
    filterItems,
    changeItemsFilters
  } = useCacheInfiniteIndexQuery<FetchProductSeasonalQueryResponse>({
    action: CategoriesPermissions.READ_PRODUCT_CATEGORIES_INDEX_ES_QUERY,
    cacheKey,
    query: FETCH_PRODUCT_SEASONAL_QUERY,
    cacheQuery: FETCH_CACHE_PRODUCT_SEASONAL_QUERY,
    defaultApi: 'fin_api',
    initialFilters,
    initialSort,
    initialLimit,
    scope,
    options
  });

  return {
    productSeasonal: items,
    productSeasonalErrorMessage: itemsError,
    productSeasonalFetched: isFetched,
    productSeasonalIsPlaceholderData: isPlaceholderData,
    productSeasonalFetchingNextPage: isFetchingNextPage,
    productSeasonalFilters: currentFilters,
    hasNextProductSeasonalPage: hasNextPage,
    loadMoreProductSeasonal: loadMoreItems,
    productSeasonalFilterSearchValue: currentFilters?.name?.ilike,
    filterProductSeasonal: filterItems,
    changeProductSeasonalFilters: changeItemsFilters
  };
}

export default useFinProductSeasonal;
