import React, { ReactNode } from 'react';

import { ClassName } from '../../../../types';

import { ProductsAiSearchEnabled } from '../../../products/productsTypes';

import { ContentWithTooltip } from '../../../../helpers/ContentWithTooltip';

import { stringsKeys } from '../../../../locales/keys';

interface ProductsAiSearchNotAvailableWrapperProps {
  children: ReactNode;
  aiSearchEnabled: ProductsAiSearchEnabled;
  className?: ClassName;
}
function ProductsAiSearchNotAvailableWrapper({
  children,
  aiSearchEnabled,
  className
}: ProductsAiSearchNotAvailableWrapperProps) {
  if (!aiSearchEnabled) {
    return <div className={className}>{children}</div>;
  }

  return (
    <ContentWithTooltip
      className={className}
      tooltipI18nText={
        stringsKeys.theFunctionalityIsNotAvailableForSearchWithAI
      }
    >
      {children}
    </ContentWithTooltip>
  );
}

export default ProductsAiSearchNotAvailableWrapper;
