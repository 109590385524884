import { useState, useCallback } from 'react';

import {
  FetchProductsSetsCacheKey,
  FetchProductsSetByUserIdScopeType
} from '../../../../../../productsSets/productsSetsTypes';

import {
  FETCH_PRODUCTS_SET_BY_USER_ID_QUERY,
  FetchProductsSetByUserIdQueryResponse
} from '../../../../../../productsSets/queries/fetchProductsSetByUserId.query';

import { useProductsSetByUserId } from '../../../../../../productsSets/hooks/useProductsSetByUserId';
import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth/useAuth';

import { ProductsListOnProductsSelect } from '../../../../../components/lists/ProductsList';

interface ProductsIndexContentSelectedProductsOptions {
  productsSetScope?: FetchProductsSetByUserIdScopeType;
  productsSetCacheKey: FetchProductsSetsCacheKey;
  onProductsSelect?: ProductsListOnProductsSelect;
}

function useProductsIndexContentSelectedProducts({
  productsSetScope,
  productsSetCacheKey,
  onProductsSelect
}: ProductsIndexContentSelectedProductsOptions) {
  const currentUser = useCurrentUser();

  const {
    productsSet,
    productsSetError,
    productsSetFetched,
    productsSetIsPlaceholderData,
    updateProductsSetCache
  } = useProductsSetByUserId<FetchProductsSetByUserIdQueryResponse>({
    cacheKey: productsSetCacheKey,
    query: FETCH_PRODUCTS_SET_BY_USER_ID_QUERY,
    userId: currentUser.get('id'),
    scope: productsSetScope,
    options: {
      onSuccess: (data) =>
        data?.productsSetByUserId?.selectedProducts
          ? onProductsSelect?.(data?.productsSetByUserId?.selectedProducts)
          : undefined
    }
  });

  const [isSelectedProductsSidebarOpen, setIsSelectedProductsSidebarOpen] =
    useState(false);

  const handleSelectedProductsSidebarOpen = useCallback<() => void>(() => {
    setIsSelectedProductsSidebarOpen(true);
  }, [setIsSelectedProductsSidebarOpen]);

  const handleSelectedProductsSidebarClose = useCallback<() => void>(() => {
    setIsSelectedProductsSidebarOpen(false);
  }, [setIsSelectedProductsSidebarOpen]);

  return {
    productsSet,
    productsSetError,
    productsSetFetched,
    productsSetIsPlaceholderData,
    updateProductsSetCache,
    isSelectedProductsSidebarOpen,
    handleSelectedProductsSidebarOpen,
    handleSelectedProductsSidebarClose
  };
}

export default useProductsIndexContentSelectedProducts;
