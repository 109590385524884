import React, { useMemo } from 'react';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import uniqBy from 'lodash/uniqBy';
import concat from 'lodash/concat';
import isEmpty from 'lodash/isEmpty';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ProductsFiltersNavBasePath,
  ProductsI18nCustomBaseTitle,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { FetchProductCategoriesQueryResponse } from '../../../queries/fetchProductCategories.query';

import { useFinProductCategories } from '../../../hooks/useFinProductCategories';
import { useCacheProductsAggregations } from '../../../hooks/useCacheProductsAggregations';

import { ProductsIndexPageCategoriesNavBody } from '../ProductsIndexPageCategoriesNav/components/ProductsIndexPageCategoriesNavBody';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';

import { getProductClientIdsCacheKeyPart } from '../../../utils/getProductClientIdsCacheKeyPart';

import { ProductCache } from '../../../ProductCache';

interface ProductsLibraryPageCategoriesNavProps {
  clientIds: ProductClientID[];
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  i18nCustomBaseTitle?: ProductsI18nCustomBaseTitle;
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function ProductsLibraryPageCategoriesNav({
  clientIds,
  filtersNavBasePath,
  i18nCustomBaseTitle,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsLibraryPageCategoriesNavProps) {
  const { productsAggregations, productsAggregationsFetched } =
    useCacheProductsAggregations({
      productsFilters
    });

  const {
    productCategories,
    productCategoriesErrorMessage,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useFinProductCategories({
    cacheKey: ProductCache.categoriesLibraryCacheKey(
      getProductClientIdsCacheKeyPart({ clientIds })
    ),
    facilityGroupsByProduct: {
      clientIds
    }
  });

  const productCategoriesWithParents = useMemo<
    FetchProductCategoriesQueryResponse[]
  >(() => {
    const parentCategories = compact(
      productCategories.map((category) => category.parent)
    );

    return sortBy(
      uniqBy([...productCategories, ...parentCategories], 'id'),
      'name'
    );
  }, [productCategories]);

  const productAggregationsCategories = useMemo<
    FetchProductCategoriesQueryResponse[]
  >(() => {
    if (isEmpty(productsAggregations)) {
      return productCategoriesWithParents;
    }

    const availableCategories = concat(
      productsAggregations?.categories,
      productsAggregations?.parent_categories
    );

    return productCategoriesWithParents?.filter((category) =>
      availableCategories?.some(
        (availableCategory) => availableCategory.id === category.id
      )
    );
  }, [productCategoriesWithParents, productsAggregations]);

  return (
    <div className="p-4">
      <AlertMessage message={productCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={
          (productCategoriesIsPlaceholderData || productCategoriesFetched) &&
          productsAggregationsFetched
        }
      >
        <ProductsIndexPageCategoriesNavBody
          productCategories={productAggregationsCategories}
          allProductCategories={productCategoriesWithParents}
          filtersNavBasePath={filtersNavBasePath}
          i18nCustomBaseTitle={i18nCustomBaseTitle}
          productsFilters={productsFilters}
          disabled={disabled}
          changeProductsFilters={changeProductsFilters}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ProductsLibraryPageCategoriesNav;
