import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { useProductsTypesFilter } from './hooks/useProductsTypesFilter';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckboxGroupFilter } from '../../../../../helpers/filters/CheckboxGroupFilter';
import { Translate } from '../../../../../helpers/Translate';

import { categoriesKeys } from '../../../../../locales/keys';

interface ProductsTypesFilterProps {
  clientIds?: ProductClientID[];
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'productTypeId' as const;

function ProductsTypesFilter({
  clientIds,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsTypesFilterProps) {
  const filterValue = productsFilters?.[name]?.in;

  const {
    allProductsTypesFilterData,
    productTypesFilterErrorMessage,
    productTypesFilterFetched,
    productTypesFilterIsPlaceholderData,
    handleChangeProductsTypesFilter
  } = useProductsTypesFilter({
    clientIds,
    selectedIds: filterValue,
    name,
    productsFilters,
    changeProductsFilters
  });

  if (
    (productTypesFilterIsPlaceholderData || productTypesFilterFetched) &&
    isEmpty(allProductsTypesFilterData)
  ) {
    return null;
  }

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.type} />
        </h6>
      </div>
      <AlertMessage message={productTypesFilterErrorMessage} />
      <LoadingSkeleton
        loaded={
          productTypesFilterIsPlaceholderData || productTypesFilterFetched
        }
      >
        <CheckboxGroupFilter
          dataGa="products-types-filter"
          inputWrapperClassName="flex flex-col sm:flex-row sm:flex-wrap -mb-2"
          name={name}
          items={allProductsTypesFilterData}
          value={productsFilters?.[name]?.in as string[]}
          disabled={disabled}
          onChange={handleChangeProductsTypesFilter}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ProductsTypesFilter;
