import React from 'react';

import {
  ChangeProductsFiltersFunc,
  FetchProductsCacheKeys,
  FetchProductsFilters,
  FetchProductsSort,
  ProductsAiSearchEnabled
} from '../../../productsTypes';

import { IconsEnum } from '../../../../../assets/icons/types';

import { IndexTablePageHeader } from '../../../../common/components/headers/IndexTablePageHeader';
import {
  ProductsAiSearchOnClipGlobalSearch,
  ProductsAiSearchOnClipSearch,
  ProductsAiSearchOnDetect,
  ProductsAiSearchOnTextSearch
} from '../../../components/containers/ProductsAiSearch';

import { ProductsIndexPageHeaderSearchInput } from '../ProductsIndexPageHeaderSearchInput';

import { Icon } from '../../../../../helpers/Icon';
import { Translate } from '../../../../../helpers/Translate';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';
import { NextLinkHelper } from '../../../../../helpers/links/NextLinkHelper';
import { ClearProductsCacheButton } from '../../buttons/ClearProductsCacheButton';

import { words } from '../../../../../locales/keys';

import { ProductsPermissions } from '../../../productsConstants';
import { ProductPath } from '../../../ProductPath';

interface ProductsIndexPageHeaderProps {
  aiSearchEnabled?: ProductsAiSearchEnabled;
  allowElasticSearch?: boolean;
  changeProductsFilters: ChangeProductsFiltersFunc;
  onAiClipGlobalSearchSubmit: ProductsAiSearchOnClipGlobalSearch;
  onAiClipSearchSubmit: ProductsAiSearchOnClipSearch;
  onAiDetectSearchSubmit: ProductsAiSearchOnDetect;
  onAiTextSearchSubmit: ProductsAiSearchOnTextSearch;
  productsCacheKeys: FetchProductsCacheKeys;
  productsFilters: FetchProductsFilters;
  withAiClipGlobalSearchFilter?: boolean;
  withAiClipGlobalSearch?: boolean;
  withAiClipSearch?: boolean;
  withAiDetectSearch?: boolean;
  withAiTextSearch?: boolean;
  withoutAddButton?: boolean;
  sortProducts: (nextSort: FetchProductsSort) => void;
}

function ProductsIndexPageHeader({
  aiSearchEnabled,
  allowElasticSearch,
  changeProductsFilters,
  onAiClipGlobalSearchSubmit,
  onAiClipSearchSubmit,
  onAiDetectSearchSubmit,
  onAiTextSearchSubmit,
  productsCacheKeys,
  productsFilters,
  withAiClipGlobalSearchFilter,
  withAiClipGlobalSearch,
  withAiClipSearch,
  withAiDetectSearch,
  withAiTextSearch,
  withoutAddButton,
  sortProducts
}: ProductsIndexPageHeaderProps) {
  return (
    <IndexTablePageHeader
      actionButtons={
        <>
          <CheckPermissions
            action={ProductsPermissions.READ_CLEAR_PRODUCTS_CACHE_BUTTON}
          >
            <ClearProductsCacheButton productsCacheKeys={productsCacheKeys} />
          </CheckPermissions>

          {withoutAddButton ? null : (
            <CheckPermissions
              action={ProductsPermissions.READ_NEW_PRODUCT_BUTTON}
            >
              <NextLinkHelper
                className="py-2 pl-2 pr-2 sm:pl-4 sm:pr-4 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md"
                dataGa="products-new-product-link"
                href={ProductPath.create()}
              >
                <Icon
                  className="h-6 w-6 p-0.5 sm:hidden"
                  icon={IconsEnum.PLUS}
                />
                <span className="sr-only sm:not-sr-only">
                  <Translate id={words.add} />
                </span>
              </NextLinkHelper>
            </CheckPermissions>
          )}
        </>
      }
    >
      <ProductsIndexPageHeaderSearchInput
        aiSearchEnabled={aiSearchEnabled}
        allowElasticSearch={allowElasticSearch}
        changeProductsFilters={changeProductsFilters}
        onAiClipGlobalSearchSubmit={onAiClipGlobalSearchSubmit}
        onAiClipSearchSubmit={onAiClipSearchSubmit}
        onAiDetectSearchSubmit={onAiDetectSearchSubmit}
        onAiTextSearchSubmit={onAiTextSearchSubmit}
        productsFilters={productsFilters}
        withAiClipGlobalSearchFilter={withAiClipGlobalSearchFilter}
        withAiClipGlobalSearch={withAiClipGlobalSearch}
        withAiClipSearch={withAiClipSearch}
        withAiDetectSearch={withAiDetectSearch}
        withAiTextSearch={withAiTextSearch}
        sortProducts={sortProducts}
      />
    </IndexTablePageHeader>
  );
}

export default ProductsIndexPageHeader;
