import { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { ProductCustomTagName } from '../../../../../productsTypes';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

interface ProductsElasticSearchCustomTagsFilterBadgesItemProps {
  label: ProductCustomTagName;
  amount: number;
  onRemove: (label: ProductCustomTagName) => void;
}

function ProductsElasticSearchCustomTagsFilterBadgesItem({
  label,
  amount,
  onRemove
}: ProductsElasticSearchCustomTagsFilterBadgesItemProps) {
  const handleRemove = useCallback<() => void>(() => {
    onRemove(label);
  }, [label, onRemove]);

  return (
    <span className="bg-blue-500 bg-opacity-20 dark:text-gray-200 inline-flex items-center m-0.5 px-3 py-1.5 rounded-md text-gray-800 text-sm">
      <span>{label}</span>
      &nbsp;
      <b>{amount}</b>
      <PureIconButtonHelper
        className="flex-shrink-0 ml-1 -my-1 -mr-2 h-6 w-6 rounded inline-flex items-center justify-center text-gray-800 dark:text-gray-200 hover:bg-gray-500 hover:bg-opacity-20 hover:text-gray-700 dark:hover:bg-opacity-20 dark:hover:bg-white dark:hover:text-white"
        icon={IconsEnum.X}
        iconClassName="h-3.5 w-3.5"
        onClick={handleRemove}
      />
    </span>
  );
}

export default ProductsElasticSearchCustomTagsFilterBadgesItem;
