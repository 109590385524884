import React from 'react';
import isEmpty from 'lodash/isEmpty';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { useProductsSeasonalFilter } from './hooks/useProductsSeasonalFilter';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { CheckboxGroupFilter } from '../../../../../helpers/filters/CheckboxGroupFilter';
import { Translate } from '../../../../../helpers/Translate';

import { categoriesKeys } from '../../../../../locales/keys';

interface ProductsSeasonalFilterProps {
  clientIds?: ProductClientID[];
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'seasonalId' as const;

function ProductsSeasonalFilter({
  clientIds,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsSeasonalFilterProps) {
  const filterValue = productsFilters?.[name]?.in;

  const {
    allProductsSeasonalFilterData,
    productSeasonalFilterErrorMessage,
    productSeasonalFilterFetched,
    productSeasonalFilterIsPlaceholderData,
    handleChangeProductsSeasonalFilter
  } = useProductsSeasonalFilter({
    clientIds,
    selectedIds: filterValue,
    name,
    productsFilters,
    changeProductsFilters
  });

  if (
    (productSeasonalFilterIsPlaceholderData || productSeasonalFilterFetched) &&
    isEmpty(allProductsSeasonalFilterData)
  ) {
    return null;
  }

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.seasonal} />
        </h6>
      </div>
      <AlertMessage message={productSeasonalFilterErrorMessage} />
      <LoadingSkeleton
        loaded={
          productSeasonalFilterIsPlaceholderData || productSeasonalFilterFetched
        }
      >
        <CheckboxGroupFilter
          dataGa="products-seasonal-filter"
          inputWrapperClassName="flex flex-col sm:flex-row sm:flex-wrap -mb-2"
          name={name}
          items={allProductsSeasonalFilterData}
          value={productsFilters?.[name] as string[]}
          disabled={disabled}
          onChange={handleChangeProductsSeasonalFilter}
        />
      </LoadingSkeleton>
    </div>
  );
}

export default ProductsSeasonalFilter;
