import React, { useCallback } from 'react';
import cl from 'classnames';
import size from 'lodash/size';
import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';

import {
  ChangeProductsFiltersFunc,
  FetchProductsFilterFields,
  FetchProductsFilters,
  FetchProductsSort,
  FetchProductsSortTypes,
  ProductsAiSearchEnabled
} from '../../../productsTypes';

import { IconsEnum } from '../../../../../assets/icons/types';

import {
  ProductsAiSearchDetectModalButton,
  ProductsAiSearchOnClipGlobalSearch,
  ProductsAiSearchOnClipSearch,
  ProductsAiSearchOnDetect,
  ProductsAiSearchOnTextSearch,
  ProductsAiSearchTextModalButton
} from '../../containers/ProductsAiSearch';
import { ProductsElasticSearchModalButton } from '../../buttons/ProductsElasticSearchModalButton';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

import { TextFilter } from '../../../../../helpers/filters/TextFilter';
import { ProductsAiSearchNotAvailableWrapper } from '../../../helpers/ProductsAiSearchNotAvailableWrapper';
import { ProductsAiSearchClipGlobalModalButton } from '../../containers/ProductsAiSearch/components/ProductsAiSearchClipGlobalModalButton';
import { CacheProductsSearchSuggestInput } from '../../../helpers/CacheProductsSearchSuggestInput';

import { aiSearchKeys, productsKeys, words } from '../../../../../locales/keys';

import {
  INITIAL_PRODUCTS_SORT,
  ProductsPermissions
} from '../../../productsConstants';

interface ProductsIndexPageHeaderSearchInputProps {
  aiSearchEnabled?: ProductsAiSearchEnabled;
  allowElasticSearch?: boolean;
  changeProductsFilters: ChangeProductsFiltersFunc;
  onAiClipGlobalSearchSubmit: ProductsAiSearchOnClipGlobalSearch;
  onAiClipSearchSubmit: ProductsAiSearchOnClipSearch;
  onAiDetectSearchSubmit: ProductsAiSearchOnDetect;
  onAiTextSearchSubmit: ProductsAiSearchOnTextSearch;
  productsFilters: FetchProductsFilters;
  withAiClipGlobalSearchFilter?: boolean;
  withAiClipGlobalSearch?: boolean;
  withAiClipSearch?: boolean;
  withAiDetectSearch?: boolean;
  withAiTextSearch?: boolean;
  sortProducts: (nextSort: FetchProductsSort) => void;
}

function ProductsIndexPageHeaderSearchInput({
  aiSearchEnabled,
  allowElasticSearch,
  changeProductsFilters,
  onAiClipGlobalSearchSubmit,
  onAiClipSearchSubmit,
  onAiDetectSearchSubmit,
  onAiTextSearchSubmit,
  productsFilters,
  withAiClipGlobalSearchFilter,
  withAiClipGlobalSearch,
  withAiClipSearch,
  withAiDetectSearch,
  withAiTextSearch,
  sortProducts
}: ProductsIndexPageHeaderSearchInputProps) {
  const currentUser = useCurrentUser();

  const withElasticSearch =
    allowElasticSearch &&
    currentUser.hasPermissions(
      ProductsPermissions.READ_PRODUCTS_ELASTIC_SEARCH_MODAL_BUTTON
    );

  const buttonsAmount = size(
    compact([
      withAiClipGlobalSearchFilter,
      withAiClipGlobalSearch,
      withAiClipSearch,
      withAiDetectSearch,
      withAiTextSearch,
      withElasticSearch
    ])
  );

  const handleSearch = useCallback<
    (
      changedFilters: { [nameSkuTags: string]: string },
      removeFilters: string[]
    ) => void
  >(
    ({ nameSkuTags }, removeFilters) => {
      changeProductsFilters({ nameSkuTags }, removeFilters);

      if (
        currentUser.hasPermissions(
          ProductsPermissions.READ_PRODUCTS_INDEX_ES_QUERY
        )
      ) {
        const sort = isEmpty(removeFilters)
          ? [FetchProductsSortTypes.SCORE_DESC]
          : INITIAL_PRODUCTS_SORT;

        sortProducts(sort);
      }
    },
    [changeProductsFilters, currentUser, sortProducts]
  );

  const handleSearchWithSuggest = useCallback<(nameSkuTags: string) => void>(
    (nameSkuTags) => {
      handleSearch(
        { nameSkuTags },
        nameSkuTags ? [] : [FetchProductsFilterFields.NAME_SKU_TAGS]
      );
    },
    [handleSearch]
  );

  const handleAiClipGlobalSearchFilter =
    useCallback<ProductsAiSearchOnClipGlobalSearch>(
      (data) => {
        changeProductsFilters({
          similarImage: {
            imageUrl: data.fileAttachment.file,
            projectId: null,
            taskId: null
          }
        });
        sortProducts([FetchProductsSortTypes.SCORE_DESC]);
      },
      [changeProductsFilters, sortProducts]
    );

  const withSuggestInput = currentUser.hasPermissions(
    ProductsPermissions.READ_PRODUCTS_SEARCH_SUGGEST_LIST
  );

  return (
    <div className="flex items-center w-full max-w-lg">
      <div className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full">
        <ProductsAiSearchNotAvailableWrapper aiSearchEnabled={aiSearchEnabled}>
          {withSuggestInput ? (
            <CacheProductsSearchSuggestInput
              value={productsFilters.nameSkuTags || ''}
              onSubmit={handleSearchWithSuggest}
              dataGa="products-text-filter-input"
              inputClassName={cl(
                'bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 py-2 text-gray-900 w-full',
                {
                  'pr-28': buttonsAmount === 3,
                  'pr-20': buttonsAmount === 2,
                  'pr-12': buttonsAmount !== 2 && buttonsAmount !== 3
                }
              )}
            />
          ) : (
            <TextFilter
              id="products-text-filter-input"
              dataGa="products-text-filter-input"
              inputClassName={cl(
                'bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 py-2 text-gray-900 w-full',
                {
                  'pr-28': buttonsAmount === 3,
                  'pr-20': buttonsAmount === 2,
                  'pr-12': buttonsAmount !== 2 && buttonsAmount !== 3
                }
              )}
              name={FetchProductsFilterFields.NAME_SKU_TAGS}
              value={productsFilters.nameSkuTags || ''}
              i18nPlaceholder={productsKeys.searchProductsByNameOrSku}
              disabled={aiSearchEnabled}
              onChange={handleSearch}
              changeOnEnter
            />
          )}
        </ProductsAiSearchNotAvailableWrapper>

        {!buttonsAmount ? null : (
          <div className="absolute flex inset-y-0 items-center leading-5 pr-1 right-0 text-sm">
            {withAiClipGlobalSearchFilter ? (
              <ProductsAiSearchClipGlobalModalButton
                className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                dataGa="products-clip-global-search-filter-button"
                i18nTitle={aiSearchKeys.imageSearch}
                icon={IconsEnum.PHOTO_SOLID}
                iconClassName="h-5 w-5"
                onSubmit={handleAiClipGlobalSearchFilter}
              />
            ) : null}

            {withAiClipGlobalSearch ? (
              <ProductsAiSearchClipGlobalModalButton
                className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                dataGa="products-clip-global-search-button"
                i18nTitle={aiSearchKeys.imageSearch}
                icon={IconsEnum.PHOTO_SOLID}
                iconClassName="h-5 w-5"
                onSubmit={onAiClipGlobalSearchSubmit}
                tooltipI18nText={aiSearchKeys.imageSearch}
              />
            ) : null}

            {withAiClipSearch ? (
              <ProductsAiSearchTextModalButton
                className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                dataGa="products-clip-search-button"
                i18nTitle={aiSearchKeys.aiPoweredCLIPSearch}
                icon={IconsEnum.SEARCH_AI_OUTLINE}
                iconClassName="h-5 w-5"
                tooltipI18nText={aiSearchKeys.aiPoweredCLIPSearch}
                onSubmit={onAiClipSearchSubmit}
              />
            ) : null}

            {withElasticSearch ? (
              <ProductsElasticSearchModalButton
                className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                dataGa="products-elastic-search-button"
                icon={IconsEnum.ELASTIC_SEARCH_SOLID}
                iconClassName="h-5 w-5"
                tooltipI18nText={words.elasticSearch}
              />
            ) : null}

            {withAiTextSearch ? (
              <ProductsAiSearchTextModalButton
                className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                dataGa="products-ai-text-search-button"
                i18nTitle={aiSearchKeys.aiPoweredSearch20}
                icon={IconsEnum.SEARCH_AI_OUTLINE}
                iconClassName="h-5 w-5"
                tooltipI18nText={aiSearchKeys.aiPoweredSearch20}
                onSubmit={onAiTextSearchSubmit}
              />
            ) : null}

            {withAiDetectSearch ? (
              <ProductsAiSearchDetectModalButton
                className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
                dataGa="products-ai-detect-search-button"
                icon={IconsEnum.PHOTO_AI_SOLID}
                iconClassName="h-5 w-5"
                tooltipI18nText={aiSearchKeys.aiPoweredSearch}
                onSubmit={onAiDetectSearchSubmit}
              />
            ) : null}
          </div>
        )}
      </div>
    </div>
  );
}

export default ProductsIndexPageHeaderSearchInput;
