import React, { ChangeEventHandler, useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { IsDisabled } from '../../../../../../../types';

import { TooltipDropdownHelper } from '../../../../../../../helpers/dropdowns/TooltipDropdownHelper';
import { Translate } from '../../../../../../../helpers/Translate';
import { Icon } from '../../../../../../../helpers/Icon';

import { PopoverPlacement } from '../../../../../../../helpers/Popover/popoverConstants';

import { words } from '../../../../../../../locales/keys';

interface ProductsIndexPageSubHeaderImageScaleButtonProps {
  scale: number;
  setScale: (scale: number) => void;
  disabled?: IsDisabled;
}

function ProductsIndexPageSubHeaderImageScaleButton({
  scale,
  setScale,
  disabled
}: ProductsIndexPageSubHeaderImageScaleButtonProps) {
  const handleChangeScale = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setScale(Number(e.target.value));
    },
    [setScale]
  );

  return (
    <TooltipDropdownHelper
      buttonClassName="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      dataGa="products-image-scale-dropdown-button"
      disabled={disabled}
      dropdownPlacement={PopoverPlacement.BOTTOM_END}
      icon={IconsEnum.ZOOM_IN_OUTLINE}
      iconClassName="h-5 w-5"
      popoverClassName="relative min-w-48 z-10 overflow-y-auto bg-white border border-transparent dark:bg-gray-700 dark:border-opacity-10 dark:border-white dark:text-white focus:outline-none py-1 ring-1 ring-black ring-opacity-5 rounded-md shadow-lg text-gray-700 flex flex-col max-w-80"
      tooltipI18nText={words.imageScale}
    >
      <div className="p-4 pt-2.5 flex flex-col">
        <label className="mb-2 text-sm font-medium text-gray-700 dark:text-gray-300">
          <Translate id={words.imageScale} /> :
        </label>

        <div className="flex gap-x-1 items-center">
          <Icon icon={IconsEnum.VIEW_GRID_SM} className="h-4 w-4" />
          <input
            data-ga="products-image-scale-range-input"
            id="attachment-size-range"
            type="range"
            min="168"
            max="480"
            step="1"
            value={scale}
            className="h-2 bg-gray-200 rounded-lg appearance-none cursor-pointer dark:bg-gray-600 w-56"
            onChange={handleChangeScale}
          />
          <Icon icon={IconsEnum.VIEW_GRID} className="h-4 w-4" />
        </div>
      </div>
    </TooltipDropdownHelper>
  );
}

export default ProductsIndexPageSubHeaderImageScaleButton;
