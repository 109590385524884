import React, { useCallback } from 'react';

import {
  ChangeProductsFiltersFunc,
  FetchProductsFilters,
  FetchProductsSort,
  ProductsAiSearchEnabled
} from '../../../productsTypes';

import { IconsEnum } from '../../../../../assets/icons/types';

import {
  ProductsAiSearchOnClipGlobalSearch,
  ProductsAiSearchOnClipSearch,
  ProductsAiSearchOnDetect,
  ProductsAiSearchOnTextSearch
} from '../../containers/ProductsAiSearch';

import { useReactQueryState } from '../../../../common/hooks/base/reactQuery/useReactQueryState';

import { ProductsIndexPageHeaderSearchInput } from '../ProductsIndexPageHeaderSearchInput';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { words } from '../../../../../locales/keys';

import { selectProductsMenuKey } from '../../../productsConstants';

interface SelectedProductsModalHeaderProps {
  aiSearchEnabled?: ProductsAiSearchEnabled;
  allowElasticSearch?: boolean;
  changeProductsFilters: ChangeProductsFiltersFunc;
  onAiClipGlobalSearchSubmit: ProductsAiSearchOnClipGlobalSearch;
  onAiClipSearchSubmit: ProductsAiSearchOnClipSearch;
  onAiDetectSearchSubmit: ProductsAiSearchOnDetect;
  onAiTextSearchSubmit: ProductsAiSearchOnTextSearch;
  productsFilters: FetchProductsFilters;
  withAiClipGlobalSearchFilter?: boolean;
  withAiClipGlobalSearch?: boolean;
  withAiClipSearch?: boolean;
  withAiDetectSearch?: boolean;
  withAiTextSearch?: boolean;
  sortProducts: (nextSort: FetchProductsSort) => void;
}

function SelectedProductsModalHeader({
  aiSearchEnabled,
  allowElasticSearch,
  changeProductsFilters,
  onAiClipGlobalSearchSubmit,
  onAiClipSearchSubmit,
  onAiDetectSearchSubmit,
  onAiTextSearchSubmit,
  productsFilters,
  withAiClipGlobalSearchFilter,
  withAiClipGlobalSearch,
  withAiClipSearch,
  withAiDetectSearch,
  withAiTextSearch,
  sortProducts
}: SelectedProductsModalHeaderProps) {
  const { setValue: setIsOpenMenu, value: isOpenMenu } =
    useReactQueryState<boolean>(selectProductsMenuKey);

  const onFiltersOpen = useCallback<() => void>(() => {
    setIsOpenMenu(true);
  }, [setIsOpenMenu]);

  return (
    <div className="flex sticky top-0 py-2 -my-2 z-10 bg-white dark:bg-gray-850 px-4 gap-2 items-center">
      {isOpenMenu ? null : (
        <PureIconButtonHelper
          className="py-1.5 pl-1.5 pr-1.5 sm:pr-3 sm:space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
          icon={IconsEnum.FILTER}
          i18nTextClassName="sr-only sm:not-sr-only"
          i18nText={words.filter}
          onClick={onFiltersOpen}
        />
      )}
      <ProductsIndexPageHeaderSearchInput
        aiSearchEnabled={aiSearchEnabled}
        allowElasticSearch={allowElasticSearch}
        changeProductsFilters={changeProductsFilters}
        onAiClipGlobalSearchSubmit={onAiClipGlobalSearchSubmit}
        onAiClipSearchSubmit={onAiClipSearchSubmit}
        onAiDetectSearchSubmit={onAiDetectSearchSubmit}
        onAiTextSearchSubmit={onAiTextSearchSubmit}
        productsFilters={productsFilters}
        withAiClipGlobalSearchFilter={withAiClipGlobalSearchFilter}
        withAiClipGlobalSearch={withAiClipGlobalSearch}
        withAiClipSearch={withAiClipSearch}
        withAiDetectSearch={withAiDetectSearch}
        withAiTextSearch={withAiTextSearch}
        sortProducts={sortProducts}
      />
    </div>
  );
}

export default SelectedProductsModalHeader;
