import React, { useCallback } from 'react';

import { IsDisabled } from '../../../../../types';

import { UserID } from '../../../../users/usersTypes';
import {
  FetchProductsFilters,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { Translate } from '../../../../../helpers/Translate';
import { ClientsSelectFilter } from '../../../../../helpers/filters/ClientsSelectFilter';

import { categoriesKeys } from '../../../../../locales/keys';

interface ProductsClientFilterProps {
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'clientIds' as const;

function ProductsClientFilter({
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsClientFilterProps) {
  const handleChange = useCallback<(ids: UserID[]) => void>(
    (ids) => {
      changeProductsFilters({ [name]: ids }, ids ? undefined : [name]);
    },
    [changeProductsFilters]
  );

  const filterValue = productsFilters?.[name];

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.client.select} />
        </h6>
      </div>

      <div className="flex flex-col space-y-2">
        <ClientsSelectFilter
          dataGa="products-client-filter"
          onChange={handleChange}
          value={filterValue}
          disabled={disabled}
        />
      </div>
    </div>
  );
}

export default ProductsClientFilter;
