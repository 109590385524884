import React, { memo } from 'react';

import {
  ChangeProductsFiltersFunc,
  FetchProductsFilters,
  FetchProductsTotalCount,
  ProductsFiltersNavBasePath,
  ProductsI18nCustomBaseTitle
} from '../../../productsTypes';
import { IsDisabled } from '../../../../../types';

import { useProductsIndexPageBreadcrumbs } from './hooks/useProductsIndexPageBreadcrumbs';

import { ProductsIndexPageBreadcrumbsItem } from './components/ProductsIndexPageBreadcrumbsItem';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { ProductPath } from '../../../ProductPath';

import { getChangeProductsFiltersCategoriesData } from '../../../utils/getChangeProductsFiltersCategoriesData';

import { ProductsPermissions } from '../../../productsConstants';
import { productsKeys } from '../../../../../locales/keys';

interface ProductsIndexPageBreadcrumbsProps {
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  i18nCustomBaseTitle?: ProductsI18nCustomBaseTitle;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
  productsTotalCount: FetchProductsTotalCount;
  disabled?: IsDisabled;
}

function ProductsIndexPageBreadcrumbs({
  filtersNavBasePath,
  i18nCustomBaseTitle,
  productsFilters,
  changeProductsFilters,
  productsTotalCount,
  disabled
}: ProductsIndexPageBreadcrumbsProps) {
  const {
    currentParentCategory,
    currentChildCategory,
    productCategories,
    handleResetCategoriesFilters,
    handleParentCategoryChangeFilters
  } = useProductsIndexPageBreadcrumbs({
    productsFilters,
    changeProductsFilters
  });

  return (
    <div className="flex-1 truncate flex pl-3 pr-1 h-full items-center text-base xl:text-lg text-gray-500 space-x-1.5">
      <ProductsIndexPageBreadcrumbsItem
        dataGa="products-category-breadcrumb-products"
        href={filtersNavBasePath}
        active={!currentChildCategory && !currentParentCategory}
        i18nText={i18nCustomBaseTitle || productsKeys.plural}
        filtersNavBasePath={filtersNavBasePath}
        disabled={disabled}
        onChangeFilters={handleResetCategoriesFilters}
      />

      {currentParentCategory ? (
        <>
          {currentParentCategory.parent && (
            <>
              <span>/</span>
              <ProductsIndexPageBreadcrumbsItem
                dataGa={`products-category-breadcrumb-${
                  currentParentCategory.parent.nameEn ||
                  currentParentCategory.parent.localizedName
                }`}
                href={
                  filtersNavBasePath
                    ? ProductPath.indexFiltersCustomBaseQuery(
                        filtersNavBasePath,
                        getChangeProductsFiltersCategoriesData({
                          productCategory: currentParentCategory.parent,
                          categories: productCategories
                        }).changeFilters
                      )
                    : '#'
                }
                active={false}
                text={currentParentCategory.parent.localizedName}
                filtersNavBasePath={filtersNavBasePath}
                disabled={disabled}
                onChangeFilters={handleParentCategoryChangeFilters}
              />
            </>
          )}
          <span>/</span>
          <ProductsIndexPageBreadcrumbsItem
            dataGa={`products-category-breadcrumb-${
              currentParentCategory.nameEn ||
              currentParentCategory.localizedName
            }`}
            href={
              filtersNavBasePath
                ? ProductPath.indexFiltersCustomBaseQuery(
                    filtersNavBasePath,
                    getChangeProductsFiltersCategoriesData({
                      productCategory: currentParentCategory,
                      categories: productCategories
                    }).changeFilters
                  )
                : '#'
            }
            active={!currentChildCategory}
            text={currentParentCategory.localizedName}
            filtersNavBasePath={filtersNavBasePath}
            disabled={disabled}
            onChangeFilters={handleParentCategoryChangeFilters}
          />
        </>
      ) : null}

      {currentChildCategory ? (
        <>
          <span>/</span>
          <ProductsIndexPageBreadcrumbsItem
            active
            dataGa={`products-category-breadcrumb-${
              currentChildCategory.nameEn || currentChildCategory.localizedName
            }`}
            text={currentChildCategory.localizedName}
            filtersNavBasePath={filtersNavBasePath}
            disabled={disabled}
          />
        </>
      ) : null}

      {productsTotalCount ? (
        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCTS_TOTAL_COUNT}
        >
          <span className="py-1 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600 text-black dark:text-white leading-tight ml-2">
            {productsTotalCount}
          </span>
        </CheckPermissions>
      ) : null}
    </div>
  );
}

export default memo<ProductsIndexPageBreadcrumbsProps>(
  ProductsIndexPageBreadcrumbs
);
