import React, { useCallback, useMemo, useState } from 'react';
import { useRouter } from 'next/router';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import omit from 'lodash/omit';

import {
  ChangeProductsFiltersFunc,
  FetchProductsCacheKey,
  FetchProductsCacheKeys,
  FetchProductsFilterFields,
  FetchProductsFilterProducts,
  FetchProductsFilters,
  FetchProductsFiltersToPersist,
  FetchProductsLimit,
  FetchProductsSort,
  FetchProductsSortTypes,
  OnProductSimilarSearchAction,
  ProductCategoryID,
  ProductClientID,
  ProductsFiltersNavBasePath,
  ProductsI18nCustomBaseTitle
} from '../../../productsTypes';

import {
  FetchProductsSetByUserIdScopeType,
  FetchProductsSetsCacheKey,
  FetchProductsSetsCacheKeys
} from '../../../../productsSets/productsSetsTypes';
import { TeamNanoID } from '../../../../teams/teamsTypes';
import { TogglePreventModalCloseAction } from '../../../../../helpers/modals/modalsTypes';

import {
  FETCH_PRODUCTS_QUERY,
  FetchProductsQueryResponse
} from '../../../queries/fetchProducts.query';
import { FETCH_SHOW_PRODUCT_QUERY } from '../../../queries/fetchShowProduct.query';
import { CACHE_FETCH_PRODUCTS_QUERY } from '../../../queries/fetchCacheProducts.query';
import { CACHE_FETCH_SHOW_PRODUCT_QUERY } from '../../../queries/fetchCacheShowProduct.query';

import { useCacheProducts } from '../../../hooks/useCacheProducts';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useMainScroll } from '../../../../../app/hooks/useMainScroll';
import { useProductsFiltersCache } from '../../../hooks/useProductsFiltersCache';
import { useProductsIndexContentAiSearch } from './hooks/useProductsIndexContentAiSearch';
import { useProductsIndexContentCategoriesFilters } from './hooks/useProductsIndexContentCategoriesFilters';
import { useProductsIndexContentSelectedProducts } from './hooks/useProductsIndexContentSelectedProducts';

import { ProductsIndexContentLayout } from './components/ProductsIndexContentLayout';
import { ProductsAiSearch } from '../../../components/containers/ProductsAiSearch';
import { ProductsAppliedFilters } from '../../../components/filters/ProductsAppliedFilters';
import { ProductsIndexPageHeader } from '../../headers/ProductsIndexPageHeader';
import { ProductsIndexPageMenu } from '../../menus/ProductsIndexPageMenu';
import { ProductsIndexPageSubHeader } from '../../headers/ProductsIndexPageSubHeader';
import {
  ProductsList,
  ProductsListOnProductsSelect
} from '../../../components/lists/ProductsList';
import { SelectedProductsFloatingCounter } from '../../../../selectedProducts/components/SelectedProductsFloatingCounter';
import { SelectedProductsSidebar } from '../../../../selectedProducts/components/sidebars/SelectedProductsSidebar';
import { SelectedProductsModalHeader } from '../../../components/headers/SelectedProductsModalHeader';
import { ThreeDStockProductsCategoriesPage } from '../../../../stock/pages/ThreeDStockProductsCategoriesPage';

import { LoadMoreButtonHelper } from '../../../../../helpers/buttons/LoadMoreButtonHelper';
import { NoResults } from '../../../../../helpers/NoResults';

import { ProductCache } from '../../../ProductCache';
import {
  INITIAL_PRODUCTS_SORT,
  ProductsPermissions
} from '../../../productsConstants';

import { productsKeys, words } from '../../../../../locales/keys';
import { ProductPath } from '../../../ProductPath';

interface ProductsIndexContentProps {
  allowAiSearch?: boolean;
  allowElasticSearch?: boolean;
  childCategoryIds?: ProductCategoryID[];
  clientIds?: ProductClientID[];
  companyNanoId?: TeamNanoID;
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  i18nCustomBaseTitle?: ProductsI18nCustomBaseTitle;
  initialFilters?: FetchProductsFilters;
  defaultFilters?: FetchProductsFilters;
  initialLimit: FetchProductsLimit;
  isMyLibrary?: boolean;
  isStockPage?: boolean;
  pageAction?: string;
  productParentCategoryIds?: ProductCategoryID[];
  productsBaseCacheKey: FetchProductsCacheKey;
  productsCacheKey: FetchProductsCacheKey;
  productsSetCacheKey: FetchProductsSetsCacheKey;
  productsSetScope?: FetchProductsSetByUserIdScopeType;
  withoutActionError?: boolean;
  withoutAddButton?: boolean;
  withoutMyCompanyFavorite?: boolean;
  onProductsAttach?: ProductsListOnProductsSelect;
  onProductsSelect?: ProductsListOnProductsSelect;
  togglePreventModalClose?: TogglePreventModalCloseAction;
  isModal?: boolean;
}

function ProductsIndexContent({
  allowAiSearch,
  allowElasticSearch,
  childCategoryIds,
  clientIds,
  companyNanoId,
  filtersNavBasePath,
  i18nCustomBaseTitle,
  initialFilters,
  defaultFilters,
  initialLimit,
  isMyLibrary,
  isStockPage,
  pageAction,
  productParentCategoryIds,
  productsBaseCacheKey,
  productsCacheKey,
  productsSetCacheKey,
  productsSetScope,
  withoutActionError,
  withoutAddButton,
  withoutMyCompanyFavorite,
  onProductsAttach,
  onProductsSelect,
  togglePreventModalClose,
  isModal
}: ProductsIndexContentProps) {
  const router = useRouter();
  const currentUser = useCurrentUser();

  const withAiClipGlobalSearchFilter =
    allowAiSearch &&
    currentUser.hasPermissions(
      ProductsPermissions.READ_PRODUCTS_AI_CLIP_GLOBAL_SEARCH_FILTER_MODAL_BUTTON
    );

  const productsCacheKeys = useMemo<FetchProductsCacheKeys>(
    () => [productsCacheKey],
    [productsCacheKey]
  );
  const productsSetCacheKeys = useMemo<FetchProductsSetsCacheKeys>(
    () => [productsSetCacheKey],
    [productsSetCacheKey]
  );

  const {
    cachedProductsSort,
    changeCachedProductsFilters,
    setCachedProductsFilters,
    setCachedProductsSort
  } = useProductsFiltersCache({
    cacheKey: productsBaseCacheKey
  });

  const {
    products,
    productsError,
    productsTotalCount,
    productsFetched,
    productsFetchingNextPage,
    productsFilters,
    productsIsPlaceholderData,
    hasNextProductsPage,
    updateProductCache,
    loadMoreProducts,
    prefetchProduct,
    changeProductsFilters,
    productsSort,
    sortProducts,
    filterProducts,
    productsPage,
    productsLimit
  } = useCacheProducts<FetchProductsQueryResponse>({
    cacheKey: productsCacheKey,
    cacheQuery: CACHE_FETCH_PRODUCTS_QUERY,
    fetchCacheItemQuery: CACHE_FETCH_SHOW_PRODUCT_QUERY,
    fetchItemCacheKey: ProductCache.showCacheKey(),
    fetchItemQuery: FETCH_SHOW_PRODUCT_QUERY,
    initialFilters,
    initialLimit,
    initialSort: cachedProductsSort,
    query: FETCH_PRODUCTS_QUERY,
    trackTotalHits: true
  });

  const {
    productsSet,
    productsSetError,
    productsSetFetched,
    productsSetIsPlaceholderData,
    updateProductsSetCache,
    isSelectedProductsSidebarOpen,
    handleSelectedProductsSidebarOpen,
    handleSelectedProductsSidebarClose
  } = useProductsIndexContentSelectedProducts({
    productsSetScope,
    productsSetCacheKey,
    onProductsSelect
  });

  const { scrollTop, isNavigateBack } = useMainScroll();

  const handleChangeProductsFilters = useCallback<ChangeProductsFiltersFunc>(
    (changedFilters, removeFilters) => {
      if (!isNavigateBack) {
        scrollTop();
      }

      changeCachedProductsFilters(changedFilters, removeFilters);

      return changeProductsFilters(changedFilters, removeFilters);
    },
    [
      changeCachedProductsFilters,
      changeProductsFilters,
      isNavigateBack,
      scrollTop
    ]
  );

  const handleFilterProducts = useCallback<FetchProductsFilterProducts>(
    (nextFilters) => {
      setCachedProductsFilters(nextFilters);

      return filterProducts(nextFilters);
    },
    [filterProducts, setCachedProductsFilters]
  );

  const handleClearProductsFiltersPersistInitial = useCallback<
    () => void
  >(() => {
    handleFilterProducts(defaultFilters || initialFilters);

    setCachedProductsSort(INITIAL_PRODUCTS_SORT);
    sortProducts(INITIAL_PRODUCTS_SORT);
  }, [
    defaultFilters,
    handleFilterProducts,
    initialFilters,
    setCachedProductsSort,
    sortProducts
  ]);

  const handleSortProducts = useCallback<(nextSort: FetchProductsSort) => void>(
    (nextSort) => {
      setCachedProductsSort(nextSort);

      return sortProducts(nextSort);
    },
    [setCachedProductsSort, sortProducts]
  );

  useProductsIndexContentCategoriesFilters({
    productsFilters,
    childCategoryIds,
    productParentCategoryIds,
    changeProductsFilters: handleChangeProductsFilters,
    withoutReset: isModal
  });

  const {
    // matchResultLoading,
    aiClipSearchEnabled,
    aiSearchData,
    aiSearchEnabled,
    aiSearchProducts,
    aiSearchProductsCacheKeys,
    aiSearchProductsError,
    aiSearchProductsFetched,
    aiSearchProductsFetchingNextPage,
    aiSearchProductsIsPlaceholderData,
    aiSearchProductsLimit,
    aiSearchProductsPage,
    aiSearchProductsTotalCount,
    aiTextSearchEnabled,
    currentAiSearchDataItem,
    handleClearAiSearch,
    handleClipGlobalAiSearch,
    handleClipAiSearch,
    handleDetectAiSearch,
    handleNextAiSearch,
    handlePrevAiSearch,
    handleProductAiSearch,
    handleClipGlobalSimilarSearch,
    handleTextAiSearch,
    hasNextAiSearchProductsPage,
    loadMoreAiSearchProducts,
    updateAiSearchProductCache,
    withAiClipGlobalSearch,
    withAiClipSearch,
    withAiDetectSearch,
    withAiTextSearch
  } = useProductsIndexContentAiSearch({
    initialFilters,
    changeProductsFilters: handleChangeProductsFilters,
    scrollToStart: scrollTop,
    productsFilters,
    allowAiSearch
  });

  const handleProductSimilarSearchFilter =
    useCallback<OnProductSimilarSearchAction>(
      (product) => {
        changeProductsFilters({
          similarImage: {
            imageUrl: product.image.file,
            projectId: null,
            taskId: null
          }
        });
        sortProducts([FetchProductsSortTypes.SCORE_DESC]);

        return router.push(ProductPath.index());
      },
      [changeProductsFilters, router, sortProducts]
    );

  const [cellMinSize, setCellMinSize] = useState(262);
  const [isGridView, toggleView] = useState(true);

  const handleToggleView = useCallback<() => void>(() => {
    toggleView((prevExpanded) => !prevExpanded);
  }, []);

  const filtersToPersist = useMemo<FetchProductsFiltersToPersist>(
    () =>
      keys(
        omit(defaultFilters || initialFilters, [
          FetchProductsFilterFields.NDA,
          FetchProductsFilterFields.BLOCKED
        ])
      ) as FetchProductsFiltersToPersist,
    [defaultFilters, initialFilters]
  );

  if (isStockPage) {
    return (
      <ThreeDStockProductsCategoriesPage
        aiSearchEnabled={aiSearchEnabled}
        changeProductsFilters={handleChangeProductsFilters}
        clientIds={clientIds}
        filterProducts={handleFilterProducts}
        initialFilters={initialFilters}
        onAiClipGlobalSearch={handleClipGlobalAiSearch}
        onClearAiSearch={handleClearAiSearch}
        productsFilters={productsFilters}
        sortProducts={handleSortProducts}
        withAiClipGlobalSearch={withAiClipGlobalSearch}
        withAiClipGlobalSearchFilter={withAiClipGlobalSearchFilter}
      />
    );
  }

  return (
    <ProductsIndexContentLayout
      isModal={isModal}
      action={pageAction}
      i18nTitle={i18nCustomBaseTitle || productsKeys.plural}
      withScrollRestore
      withScrollToTop
      withoutActionError={withoutActionError}
      modalHeader={
        <SelectedProductsModalHeader
          aiSearchEnabled={aiSearchEnabled}
          allowElasticSearch={allowElasticSearch}
          changeProductsFilters={handleChangeProductsFilters}
          onAiClipGlobalSearchSubmit={handleClipGlobalAiSearch}
          onAiClipSearchSubmit={handleClipAiSearch}
          onAiDetectSearchSubmit={handleDetectAiSearch}
          onAiTextSearchSubmit={handleTextAiSearch}
          productsFilters={productsFilters}
          withAiClipGlobalSearchFilter={withAiClipGlobalSearchFilter}
          withAiClipGlobalSearch={withAiClipGlobalSearch}
          withAiClipSearch={withAiClipSearch}
          withAiDetectSearch={withAiDetectSearch}
          withAiTextSearch={withAiTextSearch}
          sortProducts={handleSortProducts}
        />
      }
      header={
        <ProductsIndexPageHeader
          aiSearchEnabled={aiSearchEnabled}
          allowElasticSearch={allowElasticSearch}
          changeProductsFilters={handleChangeProductsFilters}
          onAiClipGlobalSearchSubmit={handleClipGlobalAiSearch}
          onAiClipSearchSubmit={handleClipAiSearch}
          onAiDetectSearchSubmit={handleDetectAiSearch}
          onAiTextSearchSubmit={handleTextAiSearch}
          productsCacheKeys={productsCacheKeys}
          productsFilters={productsFilters}
          withAiClipGlobalSearchFilter={withAiClipGlobalSearchFilter}
          withAiClipGlobalSearch={withAiClipGlobalSearch}
          withAiClipSearch={withAiClipSearch}
          withAiDetectSearch={withAiDetectSearch}
          withAiTextSearch={withAiTextSearch}
          withoutAddButton={withoutAddButton}
          sortProducts={handleSortProducts}
        />
      }
      sidebar={
        isSelectedProductsSidebarOpen ? (
          <SelectedProductsSidebar
            productsSet={productsSet}
            productsSetFetched={productsSetFetched}
            productsSetError={productsSetError}
            productsSetIsPlaceholderData={productsSetIsPlaceholderData}
            productsCacheKeys={productsCacheKeys}
            productsSetCacheKeys={productsSetCacheKeys}
            onClose={handleSelectedProductsSidebarClose}
            updateProductsSetCache={updateProductsSetCache}
            withoutBackGround={isModal}
            onCustomAttach={onProductsAttach}
          />
        ) : null
      }
      customSecondaryMenu={
        <ProductsIndexPageMenu
          aiSearchEnabled={aiSearchEnabled}
          aiTextSearchEnabled={aiTextSearchEnabled}
          aiClipSearchEnabled={aiClipSearchEnabled}
          clientIds={clientIds}
          companyNanoId={companyNanoId}
          filtersNavBasePath={filtersNavBasePath}
          i18nCustomBaseTitle={i18nCustomBaseTitle}
          productsFilters={productsFilters}
          changeProductsFilters={handleChangeProductsFilters}
          withoutMyCompanyFavorite={withoutMyCompanyFavorite}
          isModal={isModal}
        />
      }
      subHeader={
        <ProductsIndexPageSubHeader
          aiSearchEnabled={aiSearchEnabled}
          changeProductsFilters={handleChangeProductsFilters}
          currentSort={productsSort}
          filtersNavBasePath={filtersNavBasePath}
          i18nCustomBaseTitle={i18nCustomBaseTitle}
          isGridView={isGridView}
          productsFilters={productsFilters}
          productsBaseCacheKey={productsBaseCacheKey}
          productsTotalCount={
            aiSearchEnabled ? aiSearchProductsTotalCount : productsTotalCount
          }
          scale={cellMinSize}
          setScale={setCellMinSize}
          sortProducts={sortProducts}
          toggleView={handleToggleView}
        />
      }
    >
      {aiSearchEnabled ? (
        <ProductsAiSearch
          aiSearchData={aiSearchData}
          currentAiSearchDataItem={currentAiSearchDataItem}
          onClear={handleClearAiSearch}
          onClipGlobalAiSearch={handleClipGlobalAiSearch}
          onClipAiSearch={handleClipAiSearch}
          onDetectAiSearch={handleDetectAiSearch}
          onNext={handleNextAiSearch}
          onPrev={handlePrevAiSearch}
          onTextAiSearch={handleTextAiSearch}
        />
      ) : null}

      <ProductsAppliedFilters
        productsFilters={productsFilters}
        clearProductsFilters={handleClearProductsFiltersPersistInitial}
        filterProducts={handleFilterProducts}
        sortProducts={handleSortProducts}
        filtersToPersist={filtersToPersist}
      />

      {!aiSearchEnabled ? (
        <>
          {productsFetched && isEmpty(products) ? (
            <NoResults
              noResultsI18nText={words.thereAreNoMatchesForSelectedFilters}
            />
          ) : (
            <ProductsList
              companyNanoId={companyNanoId}
              isMyLibrary={isMyLibrary}
              products={products}
              productsFetched={productsFetched}
              productsSetFetched={productsSetFetched}
              productsError={productsError}
              productsSetError={productsSetError}
              productsIsPlaceholderData={productsIsPlaceholderData}
              productsSetIsPlaceholderData={productsSetIsPlaceholderData}
              productsTotalCount={productsTotalCount}
              productsSet={productsSet}
              productsCacheKeys={productsCacheKeys}
              productsSetCacheKeys={productsSetCacheKeys}
              productsFilters={productsFilters}
              changeProductsFilters={handleChangeProductsFilters}
              onSelectedProductsSidebarOpen={handleSelectedProductsSidebarOpen}
              onSelectedProductsSidebarClose={
                handleSelectedProductsSidebarClose
              }
              onProductSimilarSearch={handleClipGlobalSimilarSearch}
              onProductSimilarSearchFilter={handleProductSimilarSearchFilter}
              updateProductsSetCache={updateProductsSetCache}
              updateProductCache={updateProductCache}
              onProductEditButtonMouseEnter={prefetchProduct}
              withProductPreviewLink={currentUser.hasPermissions(
                ProductsPermissions.READ_PRODUCT_PREVIEW_LINK
              )}
              cellMinSize={cellMinSize}
              isGridView={isGridView}
            />
          )}
        </>
      ) : null}

      {aiSearchEnabled ? (
        <>
          {aiSearchProductsFetched && isEmpty(aiSearchProducts) ? (
            <NoResults
              noResultsI18nText={words.thereAreNoMatchesForSelectedFilters}
            />
          ) : (
            <ProductsList
              companyNanoId={companyNanoId}
              isMyLibrary={isMyLibrary}
              products={aiSearchProducts}
              productsFetched={aiSearchProductsFetched}
              productsSetFetched={productsSetFetched}
              productsError={aiSearchProductsError}
              productsSetError={productsSetError}
              productsIsPlaceholderData={aiSearchProductsIsPlaceholderData}
              productsSetIsPlaceholderData={productsSetIsPlaceholderData}
              productsTotalCount={aiSearchProductsTotalCount}
              productsSet={productsSet}
              productsCacheKeys={aiSearchProductsCacheKeys}
              productsSetCacheKeys={productsSetCacheKeys}
              productsFilters={productsFilters}
              changeProductsFilters={handleChangeProductsFilters}
              onSelectedProductsSidebarOpen={handleSelectedProductsSidebarOpen}
              onSelectedProductsSidebarClose={
                handleSelectedProductsSidebarClose
              }
              updateProductsSetCache={updateProductsSetCache}
              updateProductCache={updateAiSearchProductCache}
              onProductEditButtonMouseEnter={prefetchProduct}
              onProductAiSearch={handleProductAiSearch}
              onProductSimilarSearch={handleClipGlobalSimilarSearch}
              onProductSimilarSearchFilter={handleProductSimilarSearchFilter}
              withProductPreviewLink={currentUser.hasPermissions(
                ProductsPermissions.READ_PRODUCT_PREVIEW_LINK
              )}
              togglePreventModalClose={togglePreventModalClose}
            />
          )}
        </>
      ) : null}

      {productsSetFetched && productsSet && !isSelectedProductsSidebarOpen ? (
        <SelectedProductsFloatingCounter
          productsSet={productsSet}
          productsSetCacheKey={productsSetCacheKey}
          isOpen={isSelectedProductsSidebarOpen}
          onOpen={handleSelectedProductsSidebarOpen}
          onClose={handleSelectedProductsSidebarClose}
          updateProductsSetCache={updateProductsSetCache}
        />
      ) : null}

      {!aiSearchEnabled && hasNextProductsPage && (
        <LoadMoreButtonHelper
          action={
            ProductsPermissions.READ_PRODUCTS_LOAD_MORE_BUTTON_ITEMS_COUNT
          }
          dataGa="products-load-more-button"
          itemsTotalCount={productsTotalCount}
          page={productsPage}
          limit={productsLimit}
          loadMoreItems={loadMoreProducts}
          itemsFetchingNextPage={productsFetchingNextPage}
        />
      )}

      {aiSearchEnabled && hasNextAiSearchProductsPage && (
        <LoadMoreButtonHelper
          action={
            ProductsPermissions.READ_PRODUCTS_LOAD_MORE_BUTTON_ITEMS_COUNT
          }
          dataGa="products-load-more-ai-search-button"
          itemsTotalCount={aiSearchProductsTotalCount}
          page={aiSearchProductsPage}
          limit={aiSearchProductsLimit}
          loadMoreItems={loadMoreAiSearchProducts}
          itemsFetchingNextPage={aiSearchProductsFetchingNextPage}
        />
      )}
    </ProductsIndexContentLayout>
  );
}

export default ProductsIndexContent;
