import React, { Fragment, useCallback } from 'react';
import concat from 'lodash/concat';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import {
  ChangeProductsElasticSearchFiltersFunc,
  FetchProductsElasticSearchFilters,
  FetchProductsTotalCount,
  FetchElasticSearchProductsMeta,
  FetchProductsElasticSearchFilterProducts,
  FetchProductsElasticSearchFilterFields
} from '../../../../../productsTypes';

import { useAutoFocusRefCb } from '../../../../../../../common/hooks/useAutoFocusRefCb';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';

import { ProductsElasticSearchSuggestInput } from '../../../../../helpers/ProductsElasticSearchSuggestInput';
import { ProductsElasticSearchAppliedFilters } from '../../../../filters/ProductsElasticSearchAppliedFilters';
import { ProductsElasticSearchIndexPageBreadcrumbs } from '../../../../headers/ProductsElasticSearchIndexPageBreadcrumbs';

import { TextFilter } from '../../../../../../../helpers/filters/TextFilter';
import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

import { ProductsPermissions } from '../../../../../productsConstants';
import { productsKeys, words } from '../../../../../../../locales/keys';

const REMOVE_FILTERS_ON_INPUT_CHANGE = [
  FetchProductsElasticSearchFilterFields.CUSTOM_TAG_NAMES,
  FetchProductsElasticSearchFilterFields.PRODUCT_CATEGORY_ID
];

const inputName = FetchProductsElasticSearchFilterFields.SEARCH;

interface ProductsElasticSearchContentHeaderProps {
  productsFilters: FetchProductsElasticSearchFilters;
  filterProducts: FetchProductsElasticSearchFilterProducts;
  changeProductsFilters: ChangeProductsElasticSearchFiltersFunc;
  showFiltersButton: boolean;
  onFiltersOpen: () => void;
  productsTotalCount?: FetchProductsTotalCount;
  productsMeta?: FetchElasticSearchProductsMeta;
}

function ProductsElasticSearchContentHeader({
  productsFilters,
  filterProducts,
  changeProductsFilters,
  showFiltersButton,
  onFiltersOpen,
  productsTotalCount,
  productsMeta
}: ProductsElasticSearchContentHeaderProps) {
  const currentUser = useCurrentUser();
  const handleSearch = useCallback<
    (
      changedFilters: { [name: string]: string },
      removeFilters: string[]
    ) => void
  >(
    ({ name }, removeFilters) => {
      changeProductsFilters(
        { [inputName]: name },
        concat(removeFilters, REMOVE_FILTERS_ON_INPUT_CHANGE)
      );
    },
    [changeProductsFilters]
  );
  const handleElasticSearch = useCallback<(value: string) => void>(
    (value) => {
      changeProductsFilters(
        { [inputName]: value },
        value
          ? REMOVE_FILTERS_ON_INPUT_CHANGE
          : concat([inputName], REMOVE_FILTERS_ON_INPUT_CHANGE)
      );
    },
    [changeProductsFilters]
  );

  const { autoFocusRefCb } = useAutoFocusRefCb();

  const withSuggestInput = currentUser.hasPermissions(
    ProductsPermissions.READ_PRODUCTS_ELASTIC_SEARCH_SUGGEST_INPUT
  );

  return (
    <Fragment>
      <div className="flex sticky top-0 py-2 -my-2 z-10 bg-white dark:bg-gray-850 px-4 gap-2 items-center">
        {showFiltersButton ? (
          <PureIconButtonHelper
            className="py-1.5 pl-1.5 pr-1.5 sm:pr-3 sm:space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700"
            icon={IconsEnum.FILTER}
            i18nTextClassName="sr-only sm:not-sr-only"
            i18nText={words.filter}
            onClick={onFiltersOpen}
          />
        ) : null}

        <div className="flex items-center w-full max-w-lg">
          {withSuggestInput ? (
            <ProductsElasticSearchSuggestInput
              value={productsFilters[inputName] || ''}
              autoFocus
              onSubmit={handleElasticSearch}
            />
          ) : (
            <TextFilter
              inputRef={autoFocusRefCb}
              className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full"
              inputClassName="bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full pl-12 placeholder-gray-400 pr-4 py-2 text-gray-900 w-full"
              name="name"
              value={productsFilters[inputName] || ''}
              i18nPlaceholder={productsKeys.search}
              autoComplete="off"
              type="search"
              onChange={handleSearch}
            />
          )}
        </div>
      </div>

      <div className="flex items-center sm:items-start lg:py-2 flex-col sm:flex-row px-4">
        <ProductsElasticSearchIndexPageBreadcrumbs
          changeProductsFilters={changeProductsFilters}
          productsFilters={productsFilters}
          productsTotalCount={productsTotalCount}
          productsMeta={productsMeta}
        />
      </div>

      <ProductsElasticSearchAppliedFilters
        productsMeta={productsMeta}
        productsFilters={productsFilters}
        filterProducts={filterProducts}
      />
    </Fragment>
  );
}

export default ProductsElasticSearchContentHeader;
