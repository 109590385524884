import React, { useCallback, useMemo } from 'react';
import omit from 'lodash/omit';

import { ItemsFilters } from '../../../../../types';
import { FetchProductsSort } from '../../../productsTypes';
import { IconsEnum } from '../../../../../assets/icons/types';

import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { Translate } from '../../../../../helpers/Translate';
import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../helpers/ImageHelper';

import { INITIAL_PRODUCTS_SORT } from '../../../productsConstants';

import { aiSearchKeys, words } from '../../../../../locales/keys';

interface ProductsImageFilterBadgeProps {
  filters: ItemsFilters;
  onRemoveFilter: (value: ItemsFilters) => void;
  sortProducts?: (nextSort: FetchProductsSort) => void;
}

function ProductsImageFilterBadge({
  filters,
  onRemoveFilter,
  sortProducts
}: ProductsImageFilterBadgeProps) {
  const fileAttachmentFile = useMemo(
    () => filters?.similarImage?.imageUrl || '',
    [filters]
  );

  const handleClear = useCallback<() => void>(() => {
    onRemoveFilter(omit(filters, 'similarImage'));
    sortProducts(INITIAL_PRODUCTS_SORT);
  }, [filters, onRemoveFilter, sortProducts]);

  if (!fileAttachmentFile) {
    return null;
  }

  return (
    <div className="p-2 sm:p-4 bg-gray-50 dark:bg-gray-800 rounded-lg">
      <div className="relative flex gap-4 flex-col sm:flex-row">
        <div className="shrink-0 flex sm:flex-col sm:items-center">
          <div className="h-20">
            <div className="relative">
              <ImageHelper
                className="max-h-20 max-w-36"
                src={fileAttachmentFile}
                alt={'imageSearch'}
                version={FileAttachmentItemImageVersions.MiniThumb640x640}
                tooltipAlt
                tooltipAltInteractive
                tooltipAltAppendToBody
              />
            </div>
          </div>
        </div>

        <div className="flex-1 py-2 flex min-w-0">
          <div className="w-full">
            <div className="flex mb-1.5 gap-1">
              <div className="uppercase font-bold text-gray-500 text-xs">
                <Translate id={aiSearchKeys.imageSearch} />
              </div>
            </div>
          </div>
        </div>

        <div>
          <PureIconButtonHelper
            className="py-1.5 pl-1.5 pr-3 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base bg-gray-200 hover:bg-gray-300 text-gray-800 hover:text-gray-950 dark:text-gray-200 dark:hover:text-gray-50 dark:bg-gray-700 dark:hover:bg-gray-700 focus:ring-offset-0"
            dataGa="products-image-filter-badge-clear"
            i18nText={words.clear}
            icon={IconsEnum.X}
            iconClassName="h-5 w-5"
            onClick={handleClear}
          />
        </div>
      </div>
    </div>
  );
}

export default ProductsImageFilterBadge;
