import React, { useEffect, useMemo } from 'react';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import { useFinProductMaterials } from '../../../hooks/useFinProductMaterials';

import { FetchCategoriesScopes } from '../../../../categories/categoriesTypes';
import { FilterBadgeProps } from '../../../../../helpers/filterBadges/types';
import { ItemsFilterBadge } from '../../../../../helpers/filterBadges/ItemsFilterBadge';

import { ProductCache } from '../../../ProductCache';
import { categoriesKeys } from '../../../../../locales/keys';

interface ProductsMaterialsFilterBadgeItem {
  id: string;
  label: string;
}

function ProductsMaterialsFilterBadge({
  filters,
  name,
  onRemoveFilter
}: FilterBadgeProps) {
  const {
    productMaterials,
    changeProductMaterialsFilters,
    productMaterialsFilters
  } = useFinProductMaterials({
    cacheKey: ProductCache.materialsFilterBadgeCacheKey(),
    initialFilters: {
      scope: { eq: FetchCategoriesScopes.FACILITY_MATERIALS },
      id: { in: filters?.materialIds || [] }
    }
  });

  useEffect(() => {
    if (
      isEmpty(filters?.materialIds) &&
      isEmpty(productMaterialsFilters.id?.in)
    ) {
      return;
    }

    if (!isEqual(filters?.materialIds, productMaterialsFilters.id?.in)) {
      changeProductMaterialsFilters({
        id: { in: filters?.materialIds || [] }
      });
    }
  }, [
    changeProductMaterialsFilters,
    productMaterialsFilters.id?.in,
    filters?.materialIds
  ]);

  const ProductsMaterialsFilterBadgeItems = useMemo<
    ProductsMaterialsFilterBadgeItem[]
  >(() => {
    return productMaterials.map((category) => ({
      id: category.id as string,
      label: category.localizedName
    }));
  }, [productMaterials]);

  return (
    <ItemsFilterBadge
      dataGa="products-materials-filter-badge-clear"
      filters={filters}
      i18nLabel={categoriesKeys.materials.singular}
      items={ProductsMaterialsFilterBadgeItems}
      name={name}
      onRemoveFilter={onRemoveFilter}
    />
  );
}

export default ProductsMaterialsFilterBadge;
