import React from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { PureTooltipIconButtonHelper } from '../../../../../../../helpers/buttons/PureTooltipIconButtonHelper';

import { words } from '../../../../../../../locales/keys';

interface ProductsIndexPageSubHeaderToggleViewButtonProps {
  isGridView: boolean;
  toggleView: () => void;
}

function ProductsIndexPageSubHeaderToggleViewButton({
  isGridView,
  toggleView
}: ProductsIndexPageSubHeaderToggleViewButtonProps) {
  return (
    <PureTooltipIconButtonHelper
      className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
      dataGa="products-toggle-view-button"
      icon={isGridView ? IconsEnum.VIEW_LIST : IconsEnum.VIEW_GRID}
      iconClassName="h-5 w-5"
      onClick={toggleView}
      tooltipI18nText={isGridView ? words.listView : words.gridView}
    />
  );
}

export default ProductsIndexPageSubHeaderToggleViewButton;
