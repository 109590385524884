import { useCallback, useMemo, useEffect } from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { useFinProductMaterials } from '../../../../../hooks/useFinProductMaterials';
// import { useCacheProductsAggregations } from '../../../../../hooks/useCacheProductsAggregations';

import { ProductCache } from '../../../../../ProductCache';

import {
  CategoryID,
  FetchCategoriesScopes,
  FetchCategoriesSortTypes,
  FetchFinCategoriesFiltersProductFilters
} from '../../../../../../categories/categoriesTypes';

import {
  FetchProductsFilters,
  FetchFinProductCategoriesFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../../../productsTypes';
import { ProductMaterialsDataItem } from '../../ProductsMaterialsFilter.types';

import { getProductClientIdsCacheKeyPart } from '../../../../../utils/getProductClientIdsCacheKeyPart';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.FACILITY_MATERIALS }
};

const initialSort = [FetchCategoriesSortTypes.ID_ASC];

interface useProductsMaterialsFilterProps {
  clientIds?: ProductClientID[];
  selectedIds: CategoryID[];
  name: string;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function useProductsMaterialsFilter({
  clientIds,
  selectedIds = [],
  name,
  productsFilters,
  changeProductsFilters
}: useProductsMaterialsFilterProps) {
  // const { productsAggregations, productsAggregationsFetched } =
  //   useCacheProductsAggregations({
  //     productsFilters
  //   });

  const categoryIds = productsFilters?.productCategoryId?.in;
  const parentCategoryIds = productsFilters?.productParentCategoryIds;

  const cacheKey = isEmpty(clientIds)
    ? ProductCache.materialsFilterCacheKey()
    : ProductCache.materialsLibraryFilterCacheKey(
        getProductClientIdsCacheKeyPart({ clientIds })
      );

  const materialsByProduct: FetchFinCategoriesFiltersProductFilters = merge(
    {},
    isEmpty(clientIds) ? null : { productClientId: { in: clientIds } },
    isEmpty(categoryIds) ? null : { productCategoryId: { in: categoryIds } },
    isEmpty(parentCategoryIds)
      ? null
      : {
          productParentCategoryId: { in: parentCategoryIds }
        }
  );

  const defaultFilters: FetchFinProductCategoriesFilters = merge(
    {},
    defaultInitialFilters,
    isEmpty(materialsByProduct) ? null : { materialsByProduct }
  );

  const {
    productMaterials,
    productMaterialsErrorMessage,
    productMaterialsFetched,
    productMaterialsIsPlaceholderData,
    productMaterialsFetchingNextPage,
    productMaterialsFilters,
    hasNextProductMaterialsPage,
    productMaterialsFilterSearchValue,
    loadMoreProductMaterials,
    filterProductMaterials,
    changeProductMaterialsFilters
  } = useFinProductMaterials({
    cacheKey,
    initialFilters: defaultFilters,
    initialSort
  });

  useEffect(() => {
    if (
      !isEqual(
        materialsByProduct.productClientId?.in,
        productMaterialsFilters?.materialsByProduct?.productClientId?.in
      ) ||
      !isEqual(
        materialsByProduct.productCategoryId?.in,
        productMaterialsFilters?.materialsByProduct?.productCategoryId?.in
      ) ||
      !isEqual(
        materialsByProduct.productParentCategoryId?.in,
        productMaterialsFilters?.materialsByProduct?.productParentCategoryId?.in
      )
    ) {
      filterProductMaterials(defaultFilters);
    }
  }, [
    categoryIds,
    clientIds,
    defaultFilters,
    filterProductMaterials,
    materialsByProduct,
    parentCategoryIds,
    productMaterialsFilters
  ]);

  const {
    productMaterials: productMaterialsSelected,
    productMaterialsFilters: productMaterialsSelectedFilters,
    changeProductMaterialsFilters: changeProductMaterialsFiltersSelected,
    productMaterialsErrorMessage: productsMaterialsSelectedErrorMessage
  } = useFinProductMaterials({
    cacheKey: ProductCache.materialsSelectedFilterCacheKey(),
    initialFilters: {
      ...defaultInitialFilters,
      id: { in: selectedIds }
    },
    initialSort,
    options: {
      enabled: !isEmpty(selectedIds),
      enabledPlaceholder: !isEmpty(selectedIds)
    }
  });

  useEffect(() => {
    if (
      isEmpty(selectedIds) &&
      isEmpty(productMaterialsSelectedFilters?.id?.in)
    ) {
      return;
    }

    if (!isEqual(selectedIds, productMaterialsSelectedFilters?.id?.in)) {
      changeProductMaterialsFiltersSelected({
        id: { in: selectedIds }
      });
    }
  }, [
    changeProductMaterialsFiltersSelected,
    productMaterialsSelectedFilters?.id?.in,
    selectedIds
  ]);

  const handleProductMaterialsFilterSearch = useCallback(
    (input: string) => {
      changeProductMaterialsFilters(
        { name: { ilike: input } },
        input ? [] : ['name']
      );
    },
    [changeProductMaterialsFilters]
  );

  const productMaterialsFilterSelectedData = useMemo(() => {
    const selectedFetchedData = productMaterialsSelected.map((category) => ({
      id: category.id as string,
      label: category.name
    }));

    const selectedData = compact(
      selectedIds?.map((id) => find(selectedFetchedData, { id }))
    );
    return selectedData;
  }, [productMaterialsSelected, selectedIds]);

  const productsMaterialsFilterData = useMemo<
    ProductMaterialsDataItem[]
  >(() => {
    const allMaterials = sortBy(
      productMaterials?.map((category) => ({
        id: category.id as string,
        label: category.name
      })),
      'label'
    );

    return allMaterials;

    // if (isEmpty(productsAggregations)) {
    //   return allMaterials;
    // }

    // const availableMaterials = productsAggregations?.materials;

    // return allMaterials?.filter((category) =>
    //   availableMaterials?.some(
    //     (availableCategory) => availableCategory.id === category.id
    //   )
    // );
  }, [productMaterials]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeProductsFilters(
        { [name]: changedFilters?.[name] || [] },
        removeFilters
      );
    },
    [changeProductsFilters, name]
  );

  return {
    productMaterialsFilterSelectedData,
    productsMaterialsFilterData,
    productMaterialsFilterErrorMessage: productMaterialsErrorMessage,
    productsMaterialsFilterSelectedErrorMessage:
      productsMaterialsSelectedErrorMessage,
    productMaterialsFilterFetched: productMaterialsFetched,
    productMaterialsFilterIsPlaceholderData: productMaterialsIsPlaceholderData,
    productMaterialsFilterFetchingNextPage: productMaterialsFetchingNextPage,
    hasNextProductMaterialsFilterPage: hasNextProductMaterialsPage,
    productMaterialsFilterSearchValue,
    handleProductMaterialsFilterSearch,
    loadMoreProductMaterialsFilter: loadMoreProductMaterials,
    handleChangeProductsMaterialsFilter: handleChange
  };
}

export default useProductsMaterialsFilter;
