import React, { useMemo } from 'react';
import map from 'lodash/map';
import size from 'lodash/size';

import {
  ID,
  ErrorMessage,
  IsFetched,
  IsPlaceholderData
} from '../../../../../../../types';

import { FetchProductsCacheKeys } from '../../../../../../products/productsTypes';
import { FetchProductsSetsCacheKeys } from '../../../../../../productsSets/productsSetsTypes';
import { TogglePreventModalCloseAction } from '../../../../../../../helpers/modals/modalsTypes';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useTableCheckable } from '../../../../../../../common/hooks/useTableCheckable';

import { SelectedLibraryItemsModalSidebar } from '../../../../../../common/components/sidebars/SelectedLibraryItemsModalSidebar';
import { SelectedProductsSidebarFooter } from '../../../../../../selectedProducts/components/sidebars/SelectedProductsSidebar/components/SelectedProductsSidebarFooter';

import {
  SelectedProductsList,
  SelectedProductsListProductsSet,
  SelectedProductsListProductsSetSelectedProducts,
  SelectedProductsListUpdateProductsSetCache
} from '../../../../../../selectedProducts/components/lists/SelectedProductsList';

import { productsKeys } from '../../../../../../../locales/keys';
import { SelectedProductsPermissions } from '../../../../../../selectedProducts/selectedProductsConstants';

interface ProductsElasticSearchContentSidebarProps {
  productsSet: SelectedProductsListProductsSet;
  productsSetFetched: IsFetched;
  productsSetError: ErrorMessage;
  productsSetIsPlaceholderData: IsPlaceholderData;
  productsCacheKeys?: FetchProductsCacheKeys;
  productsSetCacheKeys?: FetchProductsSetsCacheKeys;
  updateProductsSetCache: SelectedProductsListUpdateProductsSetCache;
  togglePreventModalClose: TogglePreventModalCloseAction;
  onClose: () => void;
}

function ProductsElasticSearchContentSidebar({
  productsSet,
  productsSetFetched,
  productsSetError,
  productsSetIsPlaceholderData,
  productsCacheKeys,
  productsSetCacheKeys,
  updateProductsSetCache,
  togglePreventModalClose,
  onClose
}: ProductsElasticSearchContentSidebarProps) {
  const currentUser = useCurrentUser();

  const selectedProducts =
    useMemo<SelectedProductsListProductsSetSelectedProducts>(
      () => productsSet?.selectedProducts || [],
      [productsSet]
    );

  const defaultCheckedIds = useMemo<ID[]>(
    () => map(selectedProducts, 'id'),
    [selectedProducts]
  );

  const checkable = currentUser.hasPermissions(
    SelectedProductsPermissions.CHANGE_SELECTED_PRODUCTS_CHECKED
  );

  const {
    checkedHash,
    checkedAll,
    handleSetCheckedIds,
    handleCheckAll,
    checkedItems
  } = useTableCheckable({
    items: selectedProducts,
    checkedIds: defaultCheckedIds
  });

  return (
    <SelectedLibraryItemsModalSidebar
      i18nTitle={productsKeys.selected}
      onClose={onClose}
      footer={
        <SelectedProductsSidebarFooter
          productsSet={productsSet}
          checkedSelectedProductIds={map(checkedItems, 'id')}
          onClose={onClose}
          updateProductsSetCache={updateProductsSetCache}
        />
      }
      selectedItemsCount={size(productsSet.selectedProducts)}
    >
      <SelectedProductsList
        productsSet={productsSet}
        productsSetFetched={productsSetFetched}
        productsSetError={productsSetError}
        productsSetIsPlaceholderData={productsSetIsPlaceholderData}
        productsCacheKeys={productsCacheKeys}
        productsSetCacheKeys={productsSetCacheKeys}
        onSelectedProductsSidebarClose={onClose}
        updateProductsSetCache={updateProductsSetCache}
        checkable={checkable}
        checkedAll={checkedAll}
        checkedHash={checkedHash}
        onCheckAll={handleCheckAll}
        onSetCheckedIds={handleSetCheckedIds}
        togglePreventModalClose={togglePreventModalClose}
      />
    </SelectedLibraryItemsModalSidebar>
  );
}

export default ProductsElasticSearchContentSidebar;
