import React from 'react';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { useProductsBrandsFilter } from './hooks/useProductsBrandsFilter';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { CheckboxAlphabeticalGroupFilter } from '../../../../../helpers/filters/CheckboxAlphabeticalGroupFilter';
import { Translate } from '../../../../../helpers/Translate';
import {
  FetchMoreInfiniteButtonHelper,
  FetchMoreInfiniteButtonHelperPlacement
} from '../../../../../helpers/buttons/FetchMoreInfiniteButtonHelper';

import { categoriesKeys, words } from '../../../../../locales/keys';

interface ProductsBrandsFilterProps {
  clientIds?: ProductClientID[];
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'brandId' as const;

function ProductsBrandsFilter({
  clientIds,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsBrandsFilterProps) {
  const filterValue = productsFilters?.[name]?.in;

  const {
    productBrandsFilterSelectedData,
    productsBrandsFilterData,
    productBrandsFilterErrorMessage,
    productsBrandsFilterSelectedErrorMessage,
    productBrandsFilterFetched,
    productBrandsFilterIsPlaceholderData,
    productBrandsFilterFetchingNextPage,
    hasNextProductBrandsFilterPage,
    productBrandsFilterSearchValue,
    loadMoreProductBrandsFilter,
    handleProductBrandsFilterSearch,
    handleChangeProductsBrandsFilter
  } = useProductsBrandsFilter({
    clientIds,
    selectedIds: filterValue,
    name,
    productsFilters,
    changeProductsFilters
  });

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.brands.plural} />
        </h6>
      </div>
      <AlertMessage
        message={
          productBrandsFilterErrorMessage ||
          productsBrandsFilterSelectedErrorMessage
        }
      />

      <CheckboxAlphabeticalGroupFilter
        dataGa="products-brands-filter"
        name={name}
        value={filterValue as string[]}
        sortedItems={productsBrandsFilterData}
        selectedItems={productBrandsFilterSelectedData}
        itemsFetched={
          productBrandsFilterIsPlaceholderData || productBrandsFilterFetched
        }
        i18nSearchPlaceholder={categoriesKeys.brands.search}
        searchValue={productBrandsFilterSearchValue}
        disabled={disabled}
        fetchMoreButton={
          <FetchMoreInfiniteButtonHelper
            dataGa="products-brands-filter"
            errorMessage={productBrandsFilterErrorMessage}
            isLoading={productBrandsFilterFetchingNextPage}
            hasNextPage={hasNextProductBrandsFilterPage}
            i18nText={words.showMore}
            wrapperClassName="relative p-2 text-center"
            className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
            onFetchMore={loadMoreProductBrandsFilter}
          />
        }
        onSearch={handleProductBrandsFilterSearch}
        onChange={handleChangeProductsBrandsFilter}
      />
    </div>
  );
}

export default ProductsBrandsFilter;
