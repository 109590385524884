import {
  ClearProductsCacheCacheKeys,
  ClearProductsCacheGqlError,
  ClearProductsCacheGqlQuery,
  ClearProductsCacheGqlStatus
} from '../../productsTypes';
import { FetchCacheItemsSources } from '../../../../types';

import { useCacheUpdateQuery } from '../../../common/hooks/base/reactQuery/useCacheUpdateQuery';

interface ClearProductsCacheOptions {
  query: ClearProductsCacheGqlQuery;
  cacheKeys?: ClearProductsCacheCacheKeys;
}

export interface ClearProductsCacheResponse {
  clearProductCache: {
    status: ClearProductsCacheGqlStatus;
  };
}

export interface ClearProductsCacheInput {
  async?: boolean;
  source?: FetchCacheItemsSources;
}

export interface ClearProductsCacheError {
  fullMessages: ClearProductsCacheGqlError;
}

const action = 'clearProductsCache';

function useClearProductsCache<ClearProductsCacheRecordType>({
  query,
  cacheKeys
}: ClearProductsCacheOptions) {
  const {
    updateCacheQueryData,
    updateCacheQuery,
    updateCacheQueryReset,
    updateCacheQueryError,
    updateCacheQueryLoading,
    updateCacheQueryErrorMessage
  } = useCacheUpdateQuery<
    ClearProductsCacheInput,
    ClearProductsCacheResponse,
    ClearProductsCacheError,
    ClearProductsCacheRecordType
  >({ action, query, cacheKeys });

  return {
    clearProductsCacheData: updateCacheQueryData,
    clearProductsCacheError: updateCacheQueryError,
    clearProductsCacheLoading: updateCacheQueryLoading,
    clearProductsCacheErrorMessage: updateCacheQueryErrorMessage,
    clearProductsCache: updateCacheQuery,
    clearProductsCacheReset: updateCacheQueryReset
  };
}

export default useClearProductsCache;
