import { useCallback, useMemo, useEffect } from 'react';
import compact from 'lodash/compact';
import find from 'lodash/find';
import merge from 'lodash/merge';
import isEqual from 'lodash/isEqual';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';
import concat from 'lodash/concat';
import uniqBy from 'lodash/uniqBy';

import {
  CategoryID,
  FetchCategoriesScopes,
  FetchCategoriesSortTypes,
  FetchFinCategoriesFiltersProductFilters
} from '../../../../../../categories/categoriesTypes';

import {
  FetchProductsFilters,
  FetchFinProductCategoriesFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../../../productsTypes';

import { useFinProductSeasonal } from '../../../../../hooks/useFinProductSeasonal';
import { usePreviousValue } from '../../../../../../../common/hooks/usePreviousValue';
// import { useCacheProductsAggregations } from '../../../../../hooks/useCacheProductsAggregations';

import { ProductCache } from '../../../../../ProductCache';

import { getProductClientIdsCacheKeyPart } from '../../../../../utils/getProductClientIdsCacheKeyPart';

const defaultInitialFilters = {
  scope: { eq: FetchCategoriesScopes.PRODUCTS_SEASONAL }
};

const initialSort = [FetchCategoriesSortTypes.ID_ASC];

function transformSeasonalByProduct(
  seasonalByProduct: FetchProductsFilters
): FetchFinCategoriesFiltersProductFilters {
  const productCategoryId = seasonalByProduct?.productCategoryId?.in
    ? { in: seasonalByProduct.productCategoryId.in }
    : undefined;
  const productParentCategoryId = seasonalByProduct?.productParentCategoryIds
    ? { in: seasonalByProduct.productParentCategoryIds }
    : undefined;

  const blocked = seasonalByProduct?.blocked;
  const brandId = seasonalByProduct?.brandId;
  const favorite = seasonalByProduct?.favorite;
  const nda = seasonalByProduct?.nda;
  const productTypeId = seasonalByProduct?.productTypeId;
  const styleId = seasonalByProduct?.styleId;

  const colorId = seasonalByProduct?.colorIds
    ? { in: seasonalByProduct.colorIds }
    : undefined;
  const materialId = seasonalByProduct?.materialIds
    ? { in: seasonalByProduct.materialIds }
    : undefined;

  return {
    productCategoryId,
    productParentCategoryId,
    blocked,
    brandId,
    colorId,
    favorite,
    materialId,
    nda,
    productTypeId,
    styleId
  };
}

interface ProductSeasonalDataItem {
  id: string;
  label: string;
}

interface useProductsSeasonalFilterProps {
  clientIds?: ProductClientID[];
  selectedIds: CategoryID[];
  name: string;
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

function useProductsSeasonalFilter({
  clientIds,
  selectedIds = [],
  name,
  productsFilters,
  changeProductsFilters
}: useProductsSeasonalFilterProps) {
  const cacheKey = isEmpty(clientIds)
    ? ProductCache.seasonalFilterCacheKey()
    : ProductCache.seasonalLibraryFilterCacheKey(
        getProductClientIdsCacheKeyPart({ clientIds })
      );

  const seasonalByProduct = transformSeasonalByProduct(productsFilters);

  const previousSeasonalByProduct = usePreviousValue(seasonalByProduct);

  const defaultFilters: FetchFinProductCategoriesFilters = merge(
    {},
    defaultInitialFilters,
    { seasonalByProduct }
  );

  const {
    productSeasonal,
    productSeasonalErrorMessage,
    productSeasonalFetched,
    productSeasonalIsPlaceholderData,
    productSeasonalFetchingNextPage,
    hasNextProductSeasonalPage,
    productSeasonalFilterSearchValue,
    loadMoreProductSeasonal,
    filterProductSeasonal,
    changeProductSeasonalFilters
  } = useFinProductSeasonal({
    cacheKey,
    initialFilters: defaultFilters,
    initialSort
  });

  // const { productsAggregations, productsAggregationsFetched } =
  //   useCacheProductsAggregations({
  //     productsFilters
  //   });

  // const productSeasonal = useMemo(() => {
  //   if (isEmpty(productsAggregations)) {
  //     return allProductSeasonal;
  //   }

  //   const availableSeasonal = productsAggregations?.seasonal;

  //   return allProductSeasonal?.filter((seasonal) =>
  //     availableSeasonal?.some(
  //       (availableCategory) => availableCategory.id === seasonal.id
  //     )
  //   );
  // }, [allProductSeasonal, productsAggregations]);

  useEffect(() => {
    if (!isEqual(seasonalByProduct, previousSeasonalByProduct)) {
      filterProductSeasonal(defaultFilters);
    }
  }, [
    defaultFilters,
    filterProductSeasonal,
    previousSeasonalByProduct,
    seasonalByProduct
  ]);

  const {
    productSeasonal: productSeasonalSelected,
    productSeasonalFilters: productSeasonalSelectedFilters,
    changeProductSeasonalFilters: changeProductSeasonalFiltersSelected,
    productSeasonalErrorMessage: productsTypesSelectedErrorMessage
  } = useFinProductSeasonal({
    cacheKey: ProductCache.seasonalSelectedFilterCacheKey(),
    initialFilters: {
      ...defaultInitialFilters,
      id: { in: selectedIds }
    },
    initialSort,
    options: {
      enabled: !isEmpty(selectedIds),
      enabledPlaceholder: !isEmpty(selectedIds)
    }
  });

  useEffect(() => {
    if (
      isEmpty(selectedIds) &&
      isEmpty(productSeasonalSelectedFilters?.id?.in)
    ) {
      return;
    }

    if (!isEqual(selectedIds, productSeasonalSelectedFilters?.id?.in)) {
      changeProductSeasonalFiltersSelected({
        id: { in: selectedIds }
      });
    }
  }, [
    changeProductSeasonalFiltersSelected,
    productSeasonalSelectedFilters?.id?.in,
    selectedIds
  ]);

  const handleProductSeasonalFilterSearch = useCallback(
    (input: string) => {
      changeProductSeasonalFilters(
        { name: { ilike: input } },
        input ? [] : ['name']
      );
    },
    [changeProductSeasonalFilters]
  );

  const productSeasonalFilterSelectedData = useMemo(() => {
    const selectedFetchedData = productSeasonalSelected.map((category) => ({
      id: category.id as string,
      label: category.name
    }));

    const selectedData = compact(
      selectedIds?.map((id) => find(selectedFetchedData, { id }))
    );
    return selectedData;
  }, [productSeasonalSelected, selectedIds]);

  const productsSeasonalFilterData = useMemo<ProductSeasonalDataItem[]>(() => {
    return sortBy(
      productSeasonal?.map((category) => ({
        id: category.id as string,
        label: category.name
      })),
      'label'
    );
  }, [productSeasonal]);

  const handleChange = useCallback<
    (
      changedFilters: { [name: string]: string[] | undefined },
      removeFilters: string[]
    ) => void
  >(
    (changedFilters, removeFilters) => {
      changeProductsFilters(
        { [name]: { in: changedFilters?.[name] || [] } },
        removeFilters
      );
    },
    [changeProductsFilters, name]
  );

  return {
    allProductsSeasonalFilterData: uniqBy(
      compact(
        concat(productSeasonalFilterSelectedData, productsSeasonalFilterData)
      ),
      'id'
    ),
    productSeasonalFilterSelectedData,
    productsSeasonalFilterData,
    productSeasonalFilterErrorMessage: productSeasonalErrorMessage,
    productsSeasonalFilterSelectedErrorMessage:
      productsTypesSelectedErrorMessage,
    productSeasonalFilterFetched: productSeasonalFetched,
    productSeasonalFilterIsPlaceholderData: productSeasonalIsPlaceholderData,
    productSeasonalFilterFetchingNextPage: productSeasonalFetchingNextPage,
    hasNextProductSeasonalFilterPage: hasNextProductSeasonalPage,
    productSeasonalFilterSearchValue,
    handleProductSeasonalFilterSearch,
    loadMoreProductSeasonalFilter: loadMoreProductSeasonal,
    handleChangeProductsSeasonalFilter: handleChange
  };
}

export default useProductsSeasonalFilter;
