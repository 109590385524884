import React, { useCallback, useState } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  IsDisabled,
  ClassName,
  I18nText,
  GADataType
} from '../../../../../types';

import { ProductsElasticSearchContent } from '../../../../products/components/contents/ProductsElasticSearchContent';

import { SimpleModalButton } from '../../../../../helpers/buttons/SimpleModalButton';

import { ProductsElasticSearchModalButtonRequiredProps } from './ProductsElasticSearchModalButton.types';
import { words } from '../../../../../locales/keys';

interface ProductsElasticSearchModalButtonProps {
  disabled?: IsDisabled;
  icon?: IconsEnum;
  iconClassName?: ClassName;
  className?: ClassName;
  tooltipI18nText?: I18nText;
}

function ProductsElasticSearchModalButton({
  className,
  dataGa,
  disabled,
  icon,
  iconClassName,
  tooltipI18nText
}: ProductsElasticSearchModalButtonProps &
  ProductsElasticSearchModalButtonRequiredProps &
  GADataType) {
  const [imageLightboxOpen, setImageLightboxOpen] = useState<boolean>(false);

  const togglePreventModalClose = useCallback<() => void>(
    () => setImageLightboxOpen((prevState) => !prevState),
    [setImageLightboxOpen]
  );

  return (
    <SimpleModalButton
      addModalClassName="min-h-full overflow-hidden"
      childrenClassName="flex-1 flex overflow-hidden h-full"
      className={className}
      dataGa={dataGa}
      disabled={disabled}
      i18nTitle={words.elasticSearch}
      icon={icon}
      iconClassName={iconClassName}
      isLoading={disabled}
      modalIcon={IconsEnum.ELASTIC_SEARCH_SOLID}
      modalSize="full"
      preventModalClose={imageLightboxOpen}
      tooltipI18nText={tooltipI18nText}
      withoutButtons
    >
      <ProductsElasticSearchContent
        togglePreventModalClose={togglePreventModalClose}
      />
    </SimpleModalButton>
  );
}

export default ProductsElasticSearchModalButton;
