import React, { memo, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';

import { IsDisabled } from '../../../types';

import {
  TagCloudWithSearch,
  TagCloudWithSearchItemsType,
  TagCloudWithSearchI18nSearchPlaceholder,
  TagCloudWithSearchOnChange
} from '../../TagCloudWithSearch';

type TagCloudWithSearchFilterValue = string[] | undefined;

interface TagCloudWithSearchFilterProps {
  name: string;
  value: TagCloudWithSearchFilterValue;
  onChange: (
    changedFilters: { [name: string]: TagCloudWithSearchFilterValue },
    removeFilters?: string[]
  ) => void;
  data?: TagCloudWithSearchItemsType;
  i18nSearchPlaceholder?: TagCloudWithSearchI18nSearchPlaceholder;
  disabled?: IsDisabled;
}

function TagCloudWithSearchFilter({
  name,
  value,
  data,
  i18nSearchPlaceholder,
  disabled,
  onChange
}: TagCloudWithSearchFilterProps) {
  const handleChange = useCallback<TagCloudWithSearchOnChange>(
    (newValue) => {
      onChange({ [name]: newValue }, isEmpty(newValue) ? [name] : []);
    },
    [name, onChange]
  );

  return (
    <TagCloudWithSearch
      value={value}
      data={data}
      i18nSearchPlaceholder={i18nSearchPlaceholder}
      disabled={disabled}
      onChange={handleChange}
    />
  );
}

export default memo(TagCloudWithSearchFilter);
