import React, { useCallback } from 'react';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { Translate } from '../../../../../helpers/Translate';
import { Checkbox } from '../../../../../helpers/Checkbox';

import { formsFields, words } from '../../../../../locales/keys';

interface ProductsFavoriteCheckboxFilterProps {
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
  withFavorite?: boolean;
  withTeamFavorite?: boolean;
}

const nameFavorite = 'favorite' as const;
const nameTeamFavorite = 'currentTeamFavorite' as const;

function ProductsFavoriteCheckboxFilter({
  changeProductsFilters,
  disabled,
  productsFilters,
  withFavorite,
  withTeamFavorite
}: ProductsFavoriteCheckboxFilterProps) {
  const favoriteChecked = productsFilters?.[nameFavorite];
  const handleFavoriteClick = useCallback(() => {
    if (favoriteChecked) {
      changeProductsFilters({}, [nameFavorite]);
    } else {
      changeProductsFilters({ [nameFavorite]: true });
    }
  }, [favoriteChecked, changeProductsFilters]);

  const teamFavoriteChecked = productsFilters?.[nameTeamFavorite];

  const handleTeamFavoriteClick = useCallback(() => {
    if (teamFavoriteChecked) {
      changeProductsFilters({}, [nameTeamFavorite]);
    } else {
      changeProductsFilters({ [nameTeamFavorite]: true });
    }
  }, [teamFavoriteChecked, changeProductsFilters]);

  return (
    <div className="p-4 space-y-1.5">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={formsFields.favorites} />
        </h6>
      </div>

      {withFavorite && (
        <Checkbox
          checkboxWrapperClassName="flex items-center"
          checked={favoriteChecked}
          dataGa="products-favorite-checkbox-filter"
          disabled={disabled}
          i18nLabel={words.showFavoriteOnly}
          onChange={handleFavoriteClick}
        />
      )}

      {withTeamFavorite && (
        <Checkbox
          checkboxWrapperClassName="flex items-center"
          checked={teamFavoriteChecked}
          dataGa="products-team-favorite-checkbox-filter"
          disabled={disabled}
          i18nLabel={words.showFavoritesOfMyCompany}
          onChange={handleTeamFavoriteClick}
        />
      )}
    </div>
  );
}

export default ProductsFavoriteCheckboxFilter;
