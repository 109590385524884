import React, { ChangeEvent, useCallback } from 'react';

import { IconsEnum } from '../../../../assets/icons/types';

import {
  TagCloudWithSearchI18nSearchPlaceholder,
  TagCloudWithSearchSearchValue,
  TagCloudWithSearchOnSearch
} from '../../TagCloudWithSearch.types';

import { useTranslate } from '../../../../common/hooks/useTranslate';

import { Icon } from '../../../Icon';

interface TagCloudWithSearchSearchProps {
  i18nSearchPlaceholder?: TagCloudWithSearchI18nSearchPlaceholder;
  searchValue?: TagCloudWithSearchSearchValue;
  onSearch: TagCloudWithSearchOnSearch;
}

function TagCloudWithSearchSearch({
  i18nSearchPlaceholder,
  searchValue,
  onSearch
}: TagCloudWithSearchSearchProps) {
  const handleChange = useCallback<(e: ChangeEvent<HTMLInputElement>) => void>(
    (e) => {
      const input = e?.target?.value || '';
      onSearch(input);
    },
    [onSearch]
  );

  const { t } = useTranslate();

  return (
    <div className="-mx-2">
      <div>
        <div className="relative">
          <div className="absolute flex inset-y-0 items-center left-0 ml-3 pointer-events-none">
            <Icon icon={IconsEnum.SEARCH} />
          </div>
          <input
            type="text"
            value={searchValue}
            placeholder={
              i18nSearchPlaceholder ? t(i18nSearchPlaceholder) : undefined
            }
            className="basic-input pl-10"
            onChange={handleChange}
          />
        </div>
      </div>
    </div>
  );
}

export default TagCloudWithSearchSearch;
