import React, { useMemo, useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import find from 'lodash/find';
import omit from 'lodash/omit';
import without from 'lodash/without';
import size from 'lodash/size';

import {
  FetchElasticSearchProductsMeta,
  FetchProductsElasticSearchFilterFields,
  FetchProductsElasticSearchFilters,
  FetchProductsElasticSearchFilterProducts,
  ProductCustomTagName
} from '../../../productsTypes';

import { ProductsElasticSearchCustomTagsFilterBadgesItem } from './components/ProductsElasticSearchCustomTagsFilterBadgesItem';

import { productsKeys } from '../../../../../locales/keys';

import { IconsEnum } from '../../../../../assets/icons/types';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { Translate } from '../../../../../helpers/Translate';

const name = FetchProductsElasticSearchFilterFields.CUSTOM_TAG_NAMES;

interface ProductsElasticSearchCustomTagsFilterBadgesProps {
  productsMeta?: FetchElasticSearchProductsMeta;
  productsFilters: FetchProductsElasticSearchFilters;
  filterProducts: FetchProductsElasticSearchFilterProducts;
}

function ProductsElasticSearchCustomTagsFilterBadges({
  productsMeta,
  productsFilters,
  filterProducts
}: ProductsElasticSearchCustomTagsFilterBadgesProps) {
  const filterValue = productsFilters?.[name];

  const selectedData = useMemo<
    {
      label: ProductCustomTagName;
      amount: number;
    }[]
  >(
    () =>
      map(filterValue, (customTagName) => ({
        label: customTagName,
        amount:
          find(
            productsMeta?.tags,
            (item) => customTagName === item?.[0]
          )?.[1] || 0
      })),
    [filterValue, productsMeta?.tags]
  );

  const handleRemoveItem = useCallback<(label: ProductCustomTagName) => void>(
    (label) => {
      const nextFilterValue = without(filterValue, label);

      if (isEmpty(nextFilterValue)) {
        filterProducts(omit(productsFilters, name));
      } else {
        filterProducts({ ...productsFilters, [name]: nextFilterValue });
      }
    },
    [filterValue, productsFilters, filterProducts]
  );

  const handleRemoveAll = useCallback<() => void>(
    () => filterProducts(omit(productsFilters, name)),
    [productsFilters, filterProducts]
  );

  if (isEmpty(filterValue)) {
    return null;
  }

  return (
    <>
      {selectedData.map(({ label, amount }) => (
        <ProductsElasticSearchCustomTagsFilterBadgesItem
          key={label}
          label={label}
          amount={amount}
          onRemove={handleRemoveItem}
        />
      ))}
      {size(selectedData) > 1 ? (
        <span className="bg-blue-500 bg-opacity-20 dark:text-gray-200 inline-flex items-center m-0.5 px-3 py-1.5 rounded-md text-gray-800 text-sm">
          <span>
            <Translate id={productsKeys.clearAllCustomTags} />
          </span>
          <PureIconButtonHelper
            className="flex-shrink-0 ml-1 -my-1 -mr-2 h-6 w-6 rounded inline-flex items-center justify-center text-gray-800 dark:text-gray-200 hover:bg-gray-500 hover:bg-opacity-20 hover:text-gray-700 dark:hover:bg-opacity-20 dark:hover:bg-white dark:hover:text-white"
            icon={IconsEnum.X}
            iconClassName="h-3.5 w-3.5"
            onClick={handleRemoveAll}
          />
        </span>
      ) : null}
    </>
  );
}

export default ProductsElasticSearchCustomTagsFilterBadges;
