import { memo } from 'react';
import isEmpty from 'lodash/isEmpty';
import ClickAwayListener from '@mui/base/ClickAwayListener';

import { IconsEnum } from '../../../../assets/icons/types';

import { useTranslate } from '../../../../common/hooks/useTranslate';

import { useProductsElasticSearchSuggestInput } from './hooks/useProductsElasticSearchSuggestInput';
import { useDropdownState } from '../../../../helpers/dropdowns/hooks/useDropdownState';

import { ProductsElasticSearchSuggestInputSuggest } from './components/ProductsElasticSearchSuggestInputSuggest';

import { PureIconButtonHelper } from '../../../../helpers/buttons/PureIconButtonHelper';

import { productsKeys } from '../../../../locales/keys';
import { Popover } from '../../../../helpers/Popover';
import { PopoverPlacement } from '../../../../helpers/Popover/popoverConstants';

interface ProductsElasticSearchSuggestInputProps {
  value?: string;
  autoFocus?: boolean;
  onSubmit: (value: string) => void;
}
function ProductsElasticSearchSuggestInput({
  value,
  autoFocus,
  onSubmit
}: ProductsElasticSearchSuggestInputProps) {
  const { t } = useTranslate();

  const {
    isPopoverOpen,
    referencePopoverElement,
    referencePopoverCallbackRef,
    closePopover,
    openPopover
  } = useDropdownState();

  const {
    inputRefCb,
    popoverItems,
    localState,
    popoverActiveIndex,
    handleSubmit,
    handleInputFocus,
    handleInputBlur,
    handleInputChange,
    handleInputKeyDown,
    handleSuggestClick
  } = useProductsElasticSearchSuggestInput({
    value,
    autoFocus,
    onSubmit,
    closePopover,
    openPopover
  });

  return (
    <ClickAwayListener onClickAway={closePopover}>
      <div
        ref={referencePopoverCallbackRef}
        className="bg-gray-100 dark:bg-gray-800 focus-within:border-gray-300 dark:focus-within:border-gray-600 focus-within:shadow-xl dark:focus-within:bg-gray-700 focus-within:bg-white dark:focus-within:text-gray-300 focus-within:text-gray-600 h-10 relative rounded-lg text-gray-400 border border-transparent w-full"
      >
        <input
          ref={inputRefCb}
          autoComplete="off"
          id="product-elastic-search-text-filter-input"
          value={localState}
          className="pl-4 bg-transparent block border-transparent dark:focus:placeholder-gray-500 dark:text-gray-200 focus:outline-none focus:ring-4 focus:ring-blue-300 focus:placeholder-gray-300 rounded-lg h-full placeholder-gray-400 pr-12 py-2 text-gray-900 w-full"
          placeholder={t(productsKeys.search)}
          type="text"
          onChange={handleInputChange}
          onFocus={handleInputFocus}
          onBlur={handleInputBlur}
          onKeyDown={handleInputKeyDown}
        />
        <div className="absolute flex inset-y-0 items-center leading-5 pr-1 right-0 text-sm">
          <PureIconButtonHelper
            className="p-1.5 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            icon={IconsEnum.SEARCH}
            id="product-elastic-search-text-filter-popover-button"
            onClick={handleSubmit}
          />
        </div>

        {localState && !isEmpty(popoverItems) && isPopoverOpen ? (
          <Popover
            className="z-10 absolute pt-1 overflow-hidden pointer-events-none w-lg"
            closePopover={closePopover}
            distanceOffset={0}
            placement={PopoverPlacement.BOTTOM_START}
            referenceElement={referencePopoverElement}
            closeOnOuterClick={false}
            withSameWidth
          >
            <div className="overflow-auto max-h-screen bg-white dark:bg-gray-850 border border-gray-300 dark:border-gray-700 flex flex-col max-h-full max-w-lg overflow-hidden pointer-events-auto rounded-xl dark:glow-lg shadow-xl w-full">
              <div
                id="search-results-dropdown-header"
                className="flex flex-shrink-0"
              />
              <div
                id="search-results-dropdown-body"
                className="flex-grow overflow-y-auto"
              >
                <div className="flex flex-col divide-y divide-gray-100 dark:divide-gray-800 px-4">
                  <div className="py-4">
                    <ul>
                      {popoverItems.map(({ value, label }, index) => (
                        <ProductsElasticSearchSuggestInputSuggest
                          active={index === popoverActiveIndex}
                          key={`${index}-${value}`}
                          value={value}
                          label={label}
                          onClick={handleSuggestClick}
                        />
                      ))}
                    </ul>
                  </div>
                </div>
              </div>
              <div
                id="search-results-dropdown-footer"
                className="flex flex-shrink-0"
              />
            </div>
          </Popover>
        ) : null}
      </div>
    </ClickAwayListener>
  );
}

export default memo<ProductsElasticSearchSuggestInputProps>(
  ProductsElasticSearchSuggestInput
);
