import { FetchItemCacheKey } from '../../../../types';

import { useBffGetQuery } from '../../../common/hooks/base/reactQuery/useBffGetQuery';

import { ProductBffUrl } from '../../ProductBffUrl';

export interface FetchProductsElasticSearchSuggestDataType {
  name?: string[];
  brand?: string[];
  sku?: string[];
  category?: string[];
  tags?: string[];
}

export interface FetchProductsElasticSearchSuggestParams {
  query?: string;
}

interface ProductsElasticSearchSuggestQueryOptions {
  keepPreviousData?: boolean;
  staleTime?: number;
  enabled?: boolean;
  enabledPlaceholder?: boolean;
}

interface ProductsElasticSearchSuggestOptions {
  cacheKey: FetchItemCacheKey;
  options?: ProductsElasticSearchSuggestQueryOptions;
  initialParams?: FetchProductsElasticSearchSuggestParams;
}

const scope = 'productsElasticSearchSuggest';

function useProductsElasticSearchSuggest({
  cacheKey,
  options,
  initialParams = {}
}: ProductsElasticSearchSuggestOptions) {
  const {
    responseData,
    error,
    isFetched,
    isFetching,
    isLoading,
    isPlaceholderData,
    currentParams,
    changeParams
  } = useBffGetQuery<
    FetchProductsElasticSearchSuggestDataType,
    FetchProductsElasticSearchSuggestParams
  >({
    cacheKey,
    scope,
    url: ProductBffUrl.elasticSearchSuggest(),
    initialParams,
    options
  });

  return {
    productsElasticSearchSuggest: responseData,
    productsElasticSearchSuggestErrorMessage: error,
    productsElasticSearchSuggestFetched: isFetched,
    productsElasticSearchSuggestLoading: isLoading,
    productsElasticSearchSuggestFetching: isFetching,
    productsElasticSearchSuggestIsPlaceholderData: isPlaceholderData,
    productsElasticSearchSuggestCurrentParams: currentParams,
    changeProductsElasticSearchSuggestParams: changeParams
  };
}

export default useProductsElasticSearchSuggest;
