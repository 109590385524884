import {
  INITIAL_PRODUCT_TYPES_FILTERS,
  INITIAL_PRODUCT_TYPES_LIMIT,
  INITIAL_PRODUCT_TYPES_SORT
} from '../../productsConstants';

import {
  FetchFinProductCategoriesFilters,
  FetchProductCategoriesCacheKey,
  FetchProductCategoriesLimit,
  FetchProductCategoriesSort
} from '../../productsTypes';

import { useCacheInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useCacheInfiniteIndexQuery';

import {
  FETCH_PRODUCT_TYPES_QUERY,
  FetchProductTypesQueryResponse
} from '../../queries/fetchProductTypes.query';
import { FETCH_CACHE_PRODUCT_TYPES_QUERY } from '../../queries/fetchCacheProductTypes.query';

import { CategoriesPermissions } from '../../../categories/categoriesConstants';

const scope = 'categories';

const options = {
  staleTime: 1000 * 60 * 60
};

interface ProductTypesDefaultOptions {
  cacheKey: FetchProductCategoriesCacheKey;
  initialFilters?: FetchFinProductCategoriesFilters;
  initialSort?: FetchProductCategoriesSort;
  initialLimit?: FetchProductCategoriesLimit;
  options?: {
    staleTime?: number;
    enabled?: boolean;
    enabledPlaceholder?: boolean;
  };
}

function useFinProductTypes({
  cacheKey,
  initialFilters = INITIAL_PRODUCT_TYPES_FILTERS,
  initialSort = INITIAL_PRODUCT_TYPES_SORT,
  initialLimit = INITIAL_PRODUCT_TYPES_LIMIT
}: ProductTypesDefaultOptions) {
  const {
    items,
    itemsError,
    isFetched,
    isPlaceholderData,
    isFetchingNextPage,
    currentFilters,
    hasNextPage,
    loadMoreItems,
    filterItems,
    changeItemsFilters
  } = useCacheInfiniteIndexQuery<FetchProductTypesQueryResponse>({
    action: CategoriesPermissions.READ_PRODUCT_CATEGORIES_INDEX_ES_QUERY,
    cacheKey,
    defaultApi: 'fin_api',
    query: FETCH_PRODUCT_TYPES_QUERY,
    cacheQuery: FETCH_CACHE_PRODUCT_TYPES_QUERY,
    initialFilters,
    initialSort,
    initialLimit,
    scope,
    options
  });

  return {
    productTypes: items,
    productTypesErrorMessage: itemsError,
    productTypesFetched: isFetched,
    productTypesIsPlaceholderData: isPlaceholderData,
    productTypesFetchingNextPage: isFetchingNextPage,
    productTypesFilters: currentFilters,
    hasNextProductTypesPage: hasNextPage,
    loadMoreProductTypes: loadMoreItems,
    productTypesFilterSearchValue: currentFilters?.name?.ilike,
    filterProductTypes: filterItems,
    changeProductTypesFilters: changeItemsFilters
  };
}

export default useFinProductTypes;
