import React, { memo } from 'react';

import { IconsEnum } from '../../../../../assets/icons/types';
import {
  FetchProductsClearProductsFilters,
  FetchProductsFilterFields,
  FetchProductsFilters,
  FetchProductsFiltersToPersist,
  FetchProductsFilterProducts,
  FetchProductsSort
} from '../../../productsTypes';

import { BooleanFilterBadge } from '../../../../../helpers/filterBadges/BooleanFilterBadge';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';
import { ProductsBrandsFilterBadge } from '../../filterBadges/ProductsBrandsFilterBadge';
import { ProductsColorsFilterBadge } from '../../filterBadges/ProductsColorsFilterBadge';
import { ProductsStylesFilterBadge } from '../../filterBadges/ProductsStylesFilterBadge';
import { ProductsMaterialsFilterBadge } from '../../filterBadges/ProductsMaterialsFilterBadge';
import { ProductsTypesFilterBadge } from '../../filterBadges/ProductsTypesFilterBadge';
import { ProductsSeasonalFilterBadge } from '../../filterBadges/ProductsSeasonalFilterBadge';
import { ProductsImageFilterBadge } from '../../filterBadges/ProductsImageFilterBadge';
import { StringFilterBadge } from '../../../../../helpers/filterBadges/StringFilterBadge';

import { isEveryPropEmpty } from '../../../../../utils/isEveryPropEmpty';

import { words } from '../../../../../locales/keys';

interface ProductsAppliedFiltersProps {
  clearProductsFilters: FetchProductsClearProductsFilters;
  productsFilters: FetchProductsFilters;
  filterProducts: FetchProductsFilterProducts;
  filtersToPersist?: FetchProductsFiltersToPersist;
  sortProducts?: (nextSort: FetchProductsSort) => void;
}

function ProductsAppliedFilters({
  clearProductsFilters,
  productsFilters,
  filterProducts,
  filtersToPersist = [],
  sortProducts
}: ProductsAppliedFiltersProps) {
  const persistantFilters: FetchProductsFiltersToPersist = [
    FetchProductsFilterFields.PRODUCT_CATEGORY_ID,
    FetchProductsFilterFields.PRODUCT_PARENT_CATEGORY_IDS,
    FetchProductsFilterFields.SIMILAR_IMAGE,
    ...filtersToPersist
  ];

  const withFilters = !isEveryPropEmpty(productsFilters, persistantFilters);

  return (
    <div className="px-4">
      <ProductsImageFilterBadge
        filters={productsFilters}
        onRemoveFilter={filterProducts}
        sortProducts={sortProducts}
      />
      <div className="flex flex-wrap -m-0.5">
        <StringFilterBadge
          filters={productsFilters}
          i18nLabel={words.search}
          name={FetchProductsFilterFields.NAME_SKU_TAGS}
          onRemoveFilter={filterProducts}
        />

        <ProductsBrandsFilterBadge
          filters={productsFilters}
          name={FetchProductsFilterFields.BRAND_ID}
          onRemoveFilter={filterProducts}
        />
        <ProductsStylesFilterBadge
          filters={productsFilters}
          name={FetchProductsFilterFields.STYLE_ID}
          onRemoveFilter={filterProducts}
        />
        <ProductsMaterialsFilterBadge
          filters={productsFilters}
          name={FetchProductsFilterFields.MATERIAL_IDS}
          onRemoveFilter={filterProducts}
        />
        <ProductsColorsFilterBadge
          filters={productsFilters}
          name={FetchProductsFilterFields.COLOR_IDS}
          onRemoveFilter={filterProducts}
        />
        <BooleanFilterBadge
          dataGa="products-blocked-filter-badge-clear"
          filters={productsFilters}
          i18nLabel={words.blocked}
          name={FetchProductsFilterFields.BLOCKED}
          onRemoveFilter={filterProducts}
        />
        <BooleanFilterBadge
          dataGa="products-nda-filter-badge-clear"
          filters={productsFilters}
          i18nLabel={words.nda}
          name={FetchProductsFilterFields.NDA}
          onRemoveFilter={filterProducts}
        />
        {withFilters && (
          <PureIconButtonHelper
            dataGa="products-clear-filters-badge"
            className="py-1.5 pl-1.5 pr-3 space-x-1 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base border border-gray-300 dark:border-gray-700 shadow-sm bg-white dark:bg-gray-800 text-gray-500 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-700 m-0.5"
            i18nText={words.clearFilters}
            icon={IconsEnum.X}
            iconClassName="w-4 h-4"
            onClick={clearProductsFilters}
          />
        )}
        <ProductsTypesFilterBadge
          filters={productsFilters}
          name={FetchProductsFilterFields.PRODUCT_TYPE_ID}
          onRemoveFilter={filterProducts}
        />
        <ProductsSeasonalFilterBadge
          filters={productsFilters}
          name={FetchProductsFilterFields.SEASONAL_ID}
          onRemoveFilter={filterProducts}
        />
      </div>
    </div>
  );
}

export default memo<ProductsAppliedFiltersProps>(ProductsAppliedFilters);
