import React from 'react';

import {
  ChangeProductsElasticSearchFiltersFunc,
  FetchProductsElasticSearchFilters,
  FetchElasticSearchProductsMeta
} from '../../../../../productsTypes';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { ProductsElasticSearchCategoriesNav } from '../../../../menus/ProductsElasticSearchCategoriesNav';
import { ProductsCustomTagsElasticSearchFilter } from '../../../../filters/ProductsCustomTagsElasticSearchFilter';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';

interface ProductsElasticSearchContentFiltersSidebarProps {
  productsFilters: FetchProductsElasticSearchFilters;
  productsMeta?: FetchElasticSearchProductsMeta;
  withCategoriesNav?: boolean;
  withCustomTagsFilter?: boolean;
  changeProductsFilters: ChangeProductsElasticSearchFiltersFunc;
  onClose: () => void;
}

function ProductsElasticSearchContentFiltersSidebar({
  productsFilters,
  productsMeta,
  withCategoriesNav,
  withCustomTagsFilter,
  changeProductsFilters,
  onClose
}: ProductsElasticSearchContentFiltersSidebarProps) {
  return (
    <div className="flex flex-col w-80 4xl:w-112 absolute xl:relative bg-white dark:bg-gray-850 border-r dark:border-gray-700 left-0 top-0 bottom-0 max-h-full max-w-full z-10">
      <div className="flex flex-col max-h-full flex-1 overflow-y-auto py-4">
        <PureIconButtonHelper
          className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 z-20 absolute top-1 right-3.5 text-gray-600 dark:text-gray-400 hover:shadow-md bg-white dark:bg-gray-900"
          icon={IconsEnum.X_OUTLINE}
          onClick={onClose}
        />
        <div id="nav-3d-stock">
          {withCategoriesNav ? (
            <ProductsElasticSearchCategoriesNav
              changeProductsFilters={changeProductsFilters}
              productsMeta={productsMeta}
              productsFilters={productsFilters}
            />
          ) : null}

          {withCustomTagsFilter ? (
            <ProductsCustomTagsElasticSearchFilter
              productsFilters={productsFilters}
              productsMeta={productsMeta}
              changeProductsFilters={changeProductsFilters}
            />
          ) : null}
        </div>
      </div>
    </div>
  );
}

export default ProductsElasticSearchContentFiltersSidebar;
