import React, { useMemo, useCallback, useState } from 'react';
import compact from 'lodash/compact';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import map from 'lodash/map';
import take from 'lodash/take';
import size from 'lodash/size';
import values from 'lodash/values';
import first from 'lodash/first';
import isEmpty from 'lodash/isEmpty';

import { IconsEnum } from '../../../../../assets/icons/types';

import {
  FetchProductsElasticSearchFilters,
  FetchElasticSearchProductsMeta,
  ChangeProductsElasticSearchFiltersFunc,
  FetchProductsElasticSearchFilterFields,
  ProductCategoryID
} from '../../../productsTypes';

import { FetchProductCategoriesQueryResponse } from '../../../queries/fetchProductCategories.query';

import { useProductCategoriesAll } from '../../../hooks/useProductCategoriesAll';

import { ProductsElasticSearchCategoriesNavItem } from './components/ProductsElasticSearchCategoriesNavItem';
import { ProductsElasticSearchCategoriesNavAmountButton } from './components/ProductsElasticSearchCategoriesNavAmountButton';

import { LoadingSkeleton } from '../../../../../helpers/LoadingSkeleton';
import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { PureIconButtonHelper } from '../../../../../helpers/buttons/PureIconButtonHelper';

import { Translate } from '../../../../../helpers/Translate';
import { productsKeys } from '../../../../../locales/keys';

const defaultListCategoriesSize = 8;

interface ProductsElasticSearchCategoriesNavProps {
  productsFilters: FetchProductsElasticSearchFilters;
  productsMeta?: FetchElasticSearchProductsMeta;
  changeProductsFilters: ChangeProductsElasticSearchFiltersFunc;
}

function ProductsElasticSearchCategoriesNav({
  productsFilters,
  productsMeta,
  changeProductsFilters
}: ProductsElasticSearchCategoriesNavProps) {
  const {
    productCategories,
    productCategoriesErrorMessage,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useProductCategoriesAll();

  const selectedProductCategoryId = productsFilters?.productCategoryId;

  const currentChildCategory =
    useMemo<FetchProductCategoriesQueryResponse>(() => {
      return find(productCategories, ['id', selectedProductCategoryId]) || null;
    }, [productCategories, selectedProductCategoryId]);

  const metaCategories = productsMeta?.categories;

  const availableCategories = useMemo<
    FetchProductCategoriesQueryResponse[]
  >(() => {
    return sortBy(
      compact(
        map(metaCategories, (metaCategory) => {
          const categoryId = first(values(metaCategory));
          return find(productCategories, ['id', categoryId]) || null;
        })
      ),
      'localizedName'
    );
  }, [productCategories, metaCategories]);

  const handleResetCategoriesFilters = useCallback(() => {
    changeProductsFilters({}, [
      FetchProductsElasticSearchFilterFields.PRODUCT_CATEGORY_ID
    ]);
  }, [changeProductsFilters]);

  const handleSelectCategory = useCallback<
    (productCategoryId: ProductCategoryID) => void
  >(
    (productCategoryId) => {
      changeProductsFilters({ productCategoryId });
    },
    [changeProductsFilters]
  );

  const [showAll, setShowAll] = useState(false);

  const visibleCategories = useMemo<
    FetchProductCategoriesQueryResponse[]
  >(() => {
    return showAll
      ? availableCategories
      : take(availableCategories, defaultListCategoriesSize);
  }, [availableCategories, showAll]);

  return (
    <div className="p-4">
      <AlertMessage message={productCategoriesErrorMessage} />
      <LoadingSkeleton
        loaded={!productCategoriesIsPlaceholderData && productCategoriesFetched}
      >
        {currentChildCategory ? (
          <div className="-mx-4 mb-1">
            <div className="flex items-center group relative px-4 py-2">
              <PureIconButtonHelper
                className="py-2 pl-2 pr-2 rounded-full inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:outline-none focus:ring-2 focus:ring-gray-900 dark:focus:ring-gray-100 hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 -mx-1 -my-2"
                icon={IconsEnum.ARROW_LEFT_OUTLINE}
                iconClassName="h-6 w-6 stroke-2"
                onClick={handleResetCategoriesFilters}
              />
              <h6 className="flex-1 font-semibold uppercase px-2">
                {currentChildCategory.localizedName}
              </h6>
            </div>
          </div>
        ) : null}

        {!currentChildCategory ? (
          <>
            <div className="-mx-4 mb-1">
              <div className="flex items-center group relative px-4 py-2">
                <h6 className="flex-1 font-semibold uppercase px-2">
                  <Translate id={productsKeys.plural} />
                </h6>
              </div>
            </div>
            {visibleCategories.map((category) => (
              <ProductsElasticSearchCategoriesNavItem
                key={category.id}
                category={category}
                onSelectCategory={handleSelectCategory}
              />
            ))}
            {size(availableCategories) > defaultListCategoriesSize ? (
              <ProductsElasticSearchCategoriesNavAmountButton
                showAll={showAll}
                setShowAll={setShowAll}
              />
            ) : null}
          </>
        ) : null}
      </LoadingSkeleton>
    </div>
  );
}

export default ProductsElasticSearchCategoriesNav;
