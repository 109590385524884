import React, { useCallback } from 'react';
import cl from 'classnames';

import {
  PathUrl,
  I18nText,
  IsDisabled,
  GADataType
} from '../../../../../../../types';

import { ProductsFiltersNavBasePath } from '../../../../../productsTypes';

import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';

interface ProductsIndexPageBreadcrumbsItemProps {
  href?: PathUrl;
  active: boolean;
  i18nText?: I18nText;
  text?: string;
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  disabled?: IsDisabled;
  onChangeFilters?: () => void;
}

function ProductsIndexPageBreadcrumbsItem({
  active,
  dataGa,
  disabled,
  filtersNavBasePath,
  href,
  i18nText,
  onChangeFilters,
  text
}: ProductsIndexPageBreadcrumbsItemProps & GADataType) {
  const handleLinkClick = useCallback<
    (e: React.MouseEvent<HTMLAnchorElement>) => void
  >(
    (e) => {
      if (!filtersNavBasePath && onChangeFilters) {
        e.preventDefault();
        onChangeFilters();
      }
    },
    [filtersNavBasePath, onChangeFilters]
  );

  if (active) {
    return (
      <span className="font-medium text-black dark:text-white">
        {i18nText ? <Translate id={i18nText} /> : text}
      </span>
    );
  }

  return (
    <NextPureLinkHelper
      dataGa={dataGa}
      href={href || '#'}
      className={cl('hover:underline', disabled ? 'pointer-events-none' : null)}
      i18nText={i18nText}
      text={text}
      onClick={handleLinkClick}
    />
  );
}

export default ProductsIndexPageBreadcrumbsItem;
