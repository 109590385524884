import React, { useMemo, memo, useCallback } from 'react';
import find from 'lodash/find';

import {
  FetchProductsElasticSearchFilters,
  FetchElasticSearchProductsMeta,
  ChangeProductsElasticSearchFiltersFunc,
  FetchProductsTotalCount,
  FetchProductsElasticSearchFilterFields
} from '../../../productsTypes';

import { useProductCategoriesAll } from '../../../hooks/useProductCategoriesAll';

import { ProductsElasticSearchIndexPageBreadcrumbsMeta } from './components/ProductsElasticSearchIndexPageBreadcrumbsMeta';

import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';
import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import { ProductsPermissions } from '../../../productsConstants';
import { productsKeys } from '../../../../../locales/keys';
import { Translate } from '../../../../../helpers/Translate';

const i18nBaseTitle = productsKeys.plural;

interface ProductsElasticSearchIndexPageBreadcrumbsProps {
  productsFilters: FetchProductsElasticSearchFilters;
  changeProductsFilters: ChangeProductsElasticSearchFiltersFunc;
  productsTotalCount: FetchProductsTotalCount;
  productsMeta?: FetchElasticSearchProductsMeta;
}

function ProductsElasticSearchIndexPageBreadcrumbs({
  productsFilters,
  changeProductsFilters,
  productsTotalCount,
  productsMeta
}: ProductsElasticSearchIndexPageBreadcrumbsProps) {
  const { productCategories } = useProductCategoriesAll();
  const selectedProductCategoryId = productsFilters?.productCategoryId;

  const currentChildCategory = useMemo(() => {
    return find(productCategories, ['id', selectedProductCategoryId]) || null;
  }, [productCategories, selectedProductCategoryId]);

  const handleResetCategoriesFilters = useCallback(() => {
    changeProductsFilters({}, [
      FetchProductsElasticSearchFilterFields.PRODUCT_CATEGORY_ID
    ]);
  }, [changeProductsFilters]);

  return (
    <div className="flex-1 truncate flex pl-3 pr-1 h-full items-center text-base xl:text-lg text-gray-500 space-x-1.5">
      {currentChildCategory ? (
        <PureButtonHelper
          className="hover:underline"
          i18nText={i18nBaseTitle}
          onClick={handleResetCategoriesFilters}
        />
      ) : (
        <span className="font-medium text-black dark:text-white">
          <Translate id={i18nBaseTitle} />
        </span>
      )}

      {currentChildCategory ? (
        <>
          <span>/</span>
          <span className="font-medium text-black dark:text-white">
            {currentChildCategory?.localizedName}
          </span>
        </>
      ) : null}
      {productsTotalCount ? (
        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCTS_TOTAL_COUNT}
        >
          <span className="py-1 px-3 text-xs font-medium rounded-full bg-gray-100 dark:bg-gray-600 text-black dark:text-white leading-tight ml-2">
            {productsTotalCount}
          </span>
        </CheckPermissions>
      ) : null}

      <CheckPermissions
        action={ProductsPermissions.READ_PRODUCTS_ELASTIC_SEARCH_META}
      >
        <ProductsElasticSearchIndexPageBreadcrumbsMeta
          productsMeta={productsMeta}
        />
      </CheckPermissions>
    </div>
  );
}

export default memo<ProductsElasticSearchIndexPageBreadcrumbsProps>(
  ProductsElasticSearchIndexPageBreadcrumbs
);
