import isEmpty from 'lodash/isEmpty';

import { CheckPermissions } from '../../../../../helpers/CheckPermissions';

import {
  FetchProductsFilters,
  ProductClientID,
  ChangeProductsFiltersFunc,
  ProductsAiSearchEnabled,
  ProductsAiTextSearchEnabled,
  ProductsAiClipSearchEnabled
} from '../../../productsTypes';

import { ProductsAiSearchNotAvailableWrapper } from '../../../helpers/ProductsAiSearchNotAvailableWrapper';

import { ProductsPermissions } from '../../../productsConstants';

import { ProductsBrandsFilter } from '../ProductsBrandsFilter';
import { ProductsColorsFilter } from '../ProductsColorsFilter';
import { ProductsMaterialsFilter } from '../ProductsMaterialsFilter';
import { ProductsNdaFilter } from '../ProductsNdaFilter';
import { ProductsStylesFilter } from '../ProductsStylesFilter';
import { ProductsClientFilter } from '../ProductsClientFilter';
import { ProductsBlockedFilter } from '../ProductsBlockedFilter';
import { ProductsFavoriteCheckboxFilter } from '../ProductsFavoriteCheckboxFilter';
import { ProductsTypesFilter } from '../ProductsTypesFilter';
import { ProductsSeasonalFilter } from '../ProductsSeasonalFilter';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';

interface ProductsIndexFiltersProps {
  aiSearchEnabled?: ProductsAiSearchEnabled;
  aiTextSearchEnabled?: ProductsAiTextSearchEnabled;
  aiClipSearchEnabled?: ProductsAiClipSearchEnabled;
  clientIds?: ProductClientID[];
  productsFilters: FetchProductsFilters;
  changeProductsFilters: ChangeProductsFiltersFunc;
  withoutMyCompanyFavorite?: boolean;
}

function ProductsIndexFilters({
  aiSearchEnabled,
  aiTextSearchEnabled,
  aiClipSearchEnabled,
  clientIds,
  productsFilters,
  changeProductsFilters,
  withoutMyCompanyFavorite
}: ProductsIndexFiltersProps) {
  const currentUser = useCurrentUser();

  return (
    <div id="filters-dropdown-body" className="flex-grow">
      <div id="filters-group" className="py-6 flex flex-col space-y-6">
        <CheckPermissions
          actions={[
            ProductsPermissions.READ_PRODUCTS_FAVORITE_CHECKBOX_FILTER,
            ProductsPermissions.READ_PRODUCTS_MY_COMPANY_FAVORITE_CHECKBOX_FILTER
          ]}
        >
          <ProductsAiSearchNotAvailableWrapper
            aiSearchEnabled={aiSearchEnabled}
          >
            <ProductsFavoriteCheckboxFilter
              changeProductsFilters={changeProductsFilters}
              disabled={aiSearchEnabled}
              productsFilters={productsFilters}
              withFavorite={currentUser.hasPermissions(
                ProductsPermissions.READ_PRODUCTS_FAVORITE_CHECKBOX_FILTER
              )}
              withTeamFavorite={
                !withoutMyCompanyFavorite &&
                currentUser.hasPermissions(
                  ProductsPermissions.READ_PRODUCTS_MY_COMPANY_FAVORITE_CHECKBOX_FILTER
                )
              }
            />
          </ProductsAiSearchNotAvailableWrapper>
        </CheckPermissions>

        <CheckPermissions action={ProductsPermissions.READ_PRODUCTS_NDA_FILTER}>
          <ProductsAiSearchNotAvailableWrapper
            aiSearchEnabled={aiSearchEnabled}
          >
            <ProductsNdaFilter
              productsFilters={productsFilters}
              disabled={aiSearchEnabled}
              changeProductsFilters={changeProductsFilters}
            />
          </ProductsAiSearchNotAvailableWrapper>
        </CheckPermissions>

        {isEmpty(clientIds) && (
          <CheckPermissions
            action={ProductsPermissions.READ_PRODUCTS_CLIENTS_FILTER}
          >
            <ProductsAiSearchNotAvailableWrapper
              aiSearchEnabled={aiSearchEnabled}
            >
              <ProductsClientFilter
                productsFilters={productsFilters}
                disabled={aiSearchEnabled}
                changeProductsFilters={changeProductsFilters}
              />
            </ProductsAiSearchNotAvailableWrapper>
          </CheckPermissions>
        )}

        <ProductsAiSearchNotAvailableWrapper
          aiSearchEnabled={aiTextSearchEnabled || aiClipSearchEnabled}
        >
          <ProductsStylesFilter
            clientIds={clientIds}
            productsFilters={productsFilters}
            disabled={aiTextSearchEnabled || aiClipSearchEnabled}
            changeProductsFilters={changeProductsFilters}
          />
        </ProductsAiSearchNotAvailableWrapper>

        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCTS_MATERIALS_FILTER}
        >
          <ProductsAiSearchNotAvailableWrapper
            aiSearchEnabled={aiTextSearchEnabled || aiClipSearchEnabled}
          >
            <ProductsMaterialsFilter
              clientIds={clientIds}
              productsFilters={productsFilters}
              disabled={aiTextSearchEnabled || aiClipSearchEnabled}
              changeProductsFilters={changeProductsFilters}
            />
          </ProductsAiSearchNotAvailableWrapper>
        </CheckPermissions>

        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCTS_COLORS_FILTER}
        >
          <ProductsAiSearchNotAvailableWrapper
            aiSearchEnabled={aiSearchEnabled}
          >
            <ProductsColorsFilter
              clientIds={clientIds}
              productsFilters={productsFilters}
              disabled={aiSearchEnabled}
              changeProductsFilters={changeProductsFilters}
            />
          </ProductsAiSearchNotAvailableWrapper>
        </CheckPermissions>

        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCTS_BLOCKED_FILTER}
        >
          <ProductsAiSearchNotAvailableWrapper
            aiSearchEnabled={aiSearchEnabled}
          >
            <ProductsBlockedFilter
              productsFilters={productsFilters}
              disabled={aiSearchEnabled}
              changeProductsFilters={changeProductsFilters}
            />
          </ProductsAiSearchNotAvailableWrapper>
        </CheckPermissions>

        <ProductsAiSearchNotAvailableWrapper
          aiSearchEnabled={aiTextSearchEnabled || aiClipSearchEnabled}
        >
          <ProductsBrandsFilter
            clientIds={clientIds}
            productsFilters={productsFilters}
            disabled={aiTextSearchEnabled || aiClipSearchEnabled}
            changeProductsFilters={changeProductsFilters}
          />
        </ProductsAiSearchNotAvailableWrapper>

        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCTS_TYPE_FILTER}
        >
          <ProductsAiSearchNotAvailableWrapper
            aiSearchEnabled={aiSearchEnabled}
          >
            <ProductsTypesFilter
              clientIds={clientIds}
              productsFilters={productsFilters}
              disabled={aiSearchEnabled}
              changeProductsFilters={changeProductsFilters}
            />
          </ProductsAiSearchNotAvailableWrapper>
        </CheckPermissions>

        <CheckPermissions
          action={ProductsPermissions.READ_PRODUCTS_SEASONAL_FILTER}
        >
          <ProductsAiSearchNotAvailableWrapper
            aiSearchEnabled={aiSearchEnabled}
          >
            <ProductsSeasonalFilter
              clientIds={clientIds}
              productsFilters={productsFilters}
              disabled={aiSearchEnabled}
              changeProductsFilters={changeProductsFilters}
            />
          </ProductsAiSearchNotAvailableWrapper>
        </CheckPermissions>
      </div>
    </div>
  );
}

export default ProductsIndexFilters;
