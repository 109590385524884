import React, { Fragment, useCallback, useState } from 'react';
import isEmpty from 'lodash/isEmpty';
import includes from 'lodash/includes';
import some from 'lodash/some';

import { ChangeProductsElasticSearchFiltersFunc } from '../../../productsTypes';

import {
  FETCH_PRODUCTS_SET_BY_USER_ID_QUERY,
  FetchProductsSetByUserIdQueryResponse
} from '../../../../productsSets/queries/fetchProductsSetByUserId.query';
import { TogglePreventModalCloseAction } from '../../../../../helpers/modals/modalsTypes';

import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useMainScroll } from '../../../../../app/hooks/useMainScroll';

import { useProductsSetByUserId } from '../../../../productsSets/hooks/useProductsSetByUserId';
import { useElasticSearchProducts } from '../../../hooks/useElasticSearchProducts';

import { SelectedProductsFloatingCounter } from '../../../../selectedProducts/components/SelectedProductsFloatingCounter';
import { ProductsList } from '../../lists/ProductsList';
import { ProductsElasticSearchContentHeader } from './components/ProductsElasticSearchContentHeader';
import { ProductsElasticSearchContentSidebar } from './components/ProductsElasticSearchContentSidebar';
import { ProductsElasticSearchContentFiltersSidebar } from './components/ProductsElasticSearchContentFiltersSidebar';

import { MainScrollWrapper } from '../../../../common/helpers/MainScrollWrapper';
import { LoadMoreButtonHelper } from '../../../../../helpers/buttons/LoadMoreButtonHelper';
import { NoResults } from '../../../../../helpers/NoResults';

import { ProductsSetCache } from '../../../../productsSets/ProductsSetCache';
import { ProductCache } from '../../../ProductCache';

import { words } from '../../../../../locales/keys';
import { ProductsPermissions } from '../../../productsConstants';

const productsCacheKey = ProductCache.elasticSearchCacheKey();
const productsCacheKeys = [productsCacheKey];
const productsSetCacheKey = ProductsSetCache.showCacheKey();
const productsSetCacheKeys = [productsSetCacheKey];

interface ProductsElasticSearchContentProps {
  togglePreventModalClose: TogglePreventModalCloseAction;
}

function ProductsElasticSearchContent({
  togglePreventModalClose
}: ProductsElasticSearchContentProps) {
  const [isEnabled, setIsEnabled] = useState<boolean>(false);

  const {
    products,
    productsError,
    productsTotalCount,
    productsMeta,
    productsFetched,
    productsFetchingNextPage,
    productsFilters,
    productsIsPlaceholderData,
    hasNextProductsPage,
    updateProductCache,
    filterProducts,
    loadMoreProducts,
    changeProductsFilters,
    productsPage,
    productsLimit
  } = useElasticSearchProducts({
    cacheKey: productsCacheKey,
    initialLimit: 150,
    options: {
      enabled: isEnabled,
      enabledPlaceholder: false,
      keepPreviousData: true
    }
  });

  const searchValue = productsFilters.search;

  const currentUser = useCurrentUser();

  const {
    productsSet,
    productsSetError,
    productsSetFetched,
    productsSetIsPlaceholderData,
    updateProductsSetCache
  } = useProductsSetByUserId<FetchProductsSetByUserIdQueryResponse>({
    cacheKey: ProductsSetCache.showCacheKey(),
    query: FETCH_PRODUCTS_SET_BY_USER_ID_QUERY,
    userId: currentUser.get('id')
  });

  const { scrollTop } = useMainScroll();

  const handleChangeProductsFilters =
    useCallback<ChangeProductsElasticSearchFiltersFunc>(
      (changedFilters, removeFilters) => {
        scrollTop();

        if (changedFilters.search === '' || includes(removeFilters, 'search')) {
          setIsEnabled(false);
        } else if (changedFilters.search) {
          setIsEnabled(true);
        }

        return changeProductsFilters(changedFilters, removeFilters);
      },
      [changeProductsFilters, scrollTop]
    );

  const [isSelectedProductsSidebarOpen, setIsSelectedProductsSidebarOpen] =
    useState(false);

  const handleSelectedProductsSidebarOpen = useCallback<() => void>(
    () => setIsSelectedProductsSidebarOpen(true),
    [setIsSelectedProductsSidebarOpen]
  );

  const handleSelectedProductsSidebarClose = useCallback<() => void>(
    () => setIsSelectedProductsSidebarOpen(false),
    [setIsSelectedProductsSidebarOpen]
  );

  const [isFiltersSidebarOpen, setIsFiltersSidebarOpen] = useState(true);

  const handleFiltersSidebarOpen = useCallback<() => void>(
    () => setIsFiltersSidebarOpen(true),
    [setIsFiltersSidebarOpen]
  );

  const handleFiltersSidebarClose = useCallback<() => void>(
    () => setIsFiltersSidebarOpen(false),
    [setIsFiltersSidebarOpen]
  );

  const withCategoriesNav =
    currentUser.hasPermissions(
      ProductsPermissions.READ_PRODUCTS_ELASTIC_CATEGORIES_NAV
    ) && !!searchValue;

  const withCustomTagsFilter =
    currentUser.hasPermissions(
      ProductsPermissions.READ_PRODUCTS_ELASTIC_CUSTOM_TAGS_FILTER
    ) && !!searchValue;

  const withFiltersSidebar = some([withCategoriesNav, withCustomTagsFilter]);

  return (
    <Fragment>
      {isFiltersSidebarOpen && withFiltersSidebar ? (
        <ProductsElasticSearchContentFiltersSidebar
          productsFilters={productsFilters}
          productsMeta={productsMeta}
          withCategoriesNav={withCategoriesNav}
          withCustomTagsFilter={withCustomTagsFilter}
          changeProductsFilters={handleChangeProductsFilters}
          onClose={handleFiltersSidebarClose}
        />
      ) : null}
      <MainScrollWrapper>
        <div className="flex-1 z-0">
          <div className="pt-6 pb-8">
            <div className="flex flex-col space-y-8">
              <ProductsElasticSearchContentHeader
                productsFilters={productsFilters}
                filterProducts={filterProducts}
                changeProductsFilters={handleChangeProductsFilters}
                showFiltersButton={!isFiltersSidebarOpen && withFiltersSidebar}
                onFiltersOpen={handleFiltersSidebarOpen}
                productsTotalCount={productsTotalCount}
                productsMeta={productsMeta}
              />
              {searchValue ? (
                <>
                  {productsFetched && isEmpty(products) ? (
                    <NoResults
                      noResultsI18nText={
                        words.thereAreNoMatchesForSelectedFilters
                      }
                    />
                  ) : (
                    <ProductsList
                      products={products}
                      productsFetched={productsFetched}
                      productsSetFetched={productsSetFetched}
                      productsError={productsError}
                      productsSetError={productsSetError}
                      productsIsPlaceholderData={productsIsPlaceholderData}
                      productsSetIsPlaceholderData={
                        productsSetIsPlaceholderData
                      }
                      productsTotalCount={productsTotalCount}
                      productsSet={productsSet}
                      productsCacheKeys={productsCacheKeys}
                      productsSetCacheKeys={productsSetCacheKeys}
                      productsFilters={productsFilters}
                      onSelectedProductsSidebarOpen={
                        handleSelectedProductsSidebarOpen
                      }
                      onSelectedProductsSidebarClose={
                        handleSelectedProductsSidebarClose
                      }
                      updateProductsSetCache={updateProductsSetCache}
                      updateProductCache={updateProductCache}
                      togglePreventModalClose={togglePreventModalClose}
                    />
                  )}
                </>
              ) : null}
              {productsSetFetched && productsSet ? (
                <SelectedProductsFloatingCounter
                  productsSet={productsSet}
                  productsSetCacheKey={productsSetCacheKey}
                  isOpen={isSelectedProductsSidebarOpen}
                  onOpen={handleSelectedProductsSidebarOpen}
                  onClose={handleSelectedProductsSidebarClose}
                  updateProductsSetCache={updateProductsSetCache}
                />
              ) : null}
              {hasNextProductsPage && (
                <LoadMoreButtonHelper
                  action={
                    ProductsPermissions.READ_PRODUCTS_LOAD_MORE_BUTTON_ITEMS_COUNT
                  }
                  itemsTotalCount={productsTotalCount}
                  page={productsPage}
                  limit={productsLimit}
                  loadMoreItems={loadMoreProducts}
                  itemsFetchingNextPage={productsFetchingNextPage}
                />
              )}
            </div>
          </div>
        </div>
      </MainScrollWrapper>
      {isSelectedProductsSidebarOpen ? (
        <ProductsElasticSearchContentSidebar
          productsSet={productsSet}
          productsSetFetched={productsSetFetched}
          productsSetError={productsSetError}
          productsSetIsPlaceholderData={productsSetIsPlaceholderData}
          productsCacheKeys={productsCacheKeys}
          productsSetCacheKeys={productsSetCacheKeys}
          updateProductsSetCache={updateProductsSetCache}
          onClose={handleSelectedProductsSidebarClose}
          togglePreventModalClose={togglePreventModalClose}
        />
      ) : null}
    </Fragment>
  );
}

export default ProductsElasticSearchContent;
