import { useCallback } from 'react';
import isEmpty from 'lodash/isEmpty';
import keys from 'lodash/keys';
import without from 'lodash/without';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import some from 'lodash/some';

import {
  FetchProductsFilters,
  ChangeProductsFiltersFunc,
  FetchProductsFilterFields
} from '../../../../../productsTypes';
import {
  useProductsAiSearch,
  aiSearchableFilterKeys,
  ProductsAiSearchDataItemType
} from '../../../../../components/containers/ProductsAiSearch';

import { useCurrentUser } from '../../../../../../../auth/hooks/useAuth';
import { useShowToastOnErrorChange } from '../../../../../../../common/hooks/useShowToastOnErrorChange';

import { ProductsPermissions } from '../../../../../productsConstants';

const categoriesFilterKeys: (keyof FetchProductsFilters)[] = [
  FetchProductsFilterFields.PRODUCT_CATEGORY_ID,
  FetchProductsFilterFields.PRODUCT_PARENT_CATEGORY_IDS
];

interface ProductsIndexContentAiSearchOptions {
  initialFilters: FetchProductsFilters;
  productsFilters: FetchProductsFilters;
  allowAiSearch?: boolean;
  changeProductsFilters: ChangeProductsFiltersFunc;
  scrollToStart: () => void;
}

function useProductsIndexContentAiSearch({
  initialFilters,
  productsFilters,
  allowAiSearch,
  changeProductsFilters,
  scrollToStart
}: ProductsIndexContentAiSearchOptions) {
  const currentUser = useCurrentUser();

  const handleClearFilters = useCallback<
    (preserveFilters?: (keyof FetchProductsFilters)[]) => void
  >(
    (preserveFilters) => {
      const removeFiltersKeys = without(
        keys(productsFilters),
        ...(preserveFilters || []),
        ...categoriesFilterKeys,
        ...keys(initialFilters)
      );
      const currentInitialFiltersValues = pick(
        productsFilters,
        keys(initialFilters)
      );

      if (
        !isEmpty(removeFiltersKeys) ||
        !isEqual(initialFilters, currentInitialFiltersValues)
      ) {
        changeProductsFilters(initialFilters, removeFiltersKeys);
      }
    },
    [changeProductsFilters, initialFilters, productsFilters]
  );

  const handleDetectSearch = useCallback<() => void>(() => {
    handleClearFilters(aiSearchableFilterKeys);

    scrollToStart();
  }, [handleClearFilters, scrollToStart]);

  const handleTextSearch = useCallback<() => void>(() => {
    handleClearFilters();

    scrollToStart();
  }, [handleClearFilters, scrollToStart]);

  const {
    aiSearchData,
    aiSearchProducts,
    aiSearchProductsCacheKeys,
    aiSearchProductsError,
    aiSearchProductsFetched,
    aiSearchProductsFetchingNextPage,
    aiSearchProductsIsPlaceholderData,
    aiSearchProductsLimit,
    aiSearchProductsPage,
    aiSearchProductsTotalCount,
    clipGlobalSimilarSearchErrorMessage,
    currentAiSearchDataItem,
    handleClearAiSearch,
    handleClipAiSearch,
    handleClipGlobalAiSearch,
    handleDetectAiSearch,
    handleNextAiSearch,
    handlePrevAiSearch,
    handleProductAiSearch,
    handleClipGlobalSimilarSearch,
    handleTextAiSearch,
    hasNextAiSearchProductsPage,
    loadMoreAiSearchProducts,
    matchResultLoading,
    updateAiSearchProductCache
  } = useProductsAiSearch({
    productsFilters,
    onDetectSearch: handleDetectSearch,
    onSearchByAngle: scrollToStart,
    onTextSearch: handleTextSearch,
    onClipSearch: handleTextSearch
  });

  useShowToastOnErrorChange({
    error: clipGlobalSimilarSearchErrorMessage
  });

  const withAiClipGlobalSearch =
    allowAiSearch &&
    currentUser.hasPermissions(
      ProductsPermissions.READ_PRODUCTS_AI_CLIP_GLOBAL_SEARCH_MODAL_BUTTON
    );

  const withAiClipSearch =
    allowAiSearch &&
    currentUser.hasPermissions(
      ProductsPermissions.READ_PRODUCTS_AI_CLIP_SEARCH_MODAL_BUTTON
    );

  const withAiDetectSearch =
    allowAiSearch &&
    currentUser.hasPermissions(
      ProductsPermissions.READ_PRODUCTS_AI_SEARCH_MODAL_BUTTON
    );

  const withAiTextSearch =
    allowAiSearch &&
    currentUser.hasPermissions(
      ProductsPermissions.READ_PRODUCTS_AI_TEXT_SEARCH_MODAL_BUTTON
    );

  const aiSearchEnabled = !!currentAiSearchDataItem;

  const aiTextSearchEnabled = some(
    aiSearchData,
    (item) => item.type === ProductsAiSearchDataItemType.TEXT_SEARCH
  );

  const aiClipSearchEnabled = some(
    aiSearchData,
    (item) => item.type === ProductsAiSearchDataItemType.CLIP_SEARCH
  );

  return {
    aiClipSearchEnabled,
    aiSearchData,
    aiSearchEnabled,
    aiSearchProducts,
    aiSearchProductsCacheKeys,
    aiSearchProductsError,
    aiSearchProductsFetched,
    aiSearchProductsFetchingNextPage,
    aiSearchProductsIsPlaceholderData,
    aiSearchProductsLimit,
    aiSearchProductsPage,
    aiSearchProductsTotalCount,
    aiTextSearchEnabled,
    currentAiSearchDataItem,
    handleClearAiSearch,
    handleClipGlobalAiSearch,
    handleClipAiSearch,
    handleDetectAiSearch,
    handleNextAiSearch,
    handlePrevAiSearch,
    handleProductAiSearch,
    handleClipGlobalSimilarSearch,
    handleTextAiSearch,
    hasNextAiSearchProductsPage,
    loadMoreAiSearchProducts,
    matchResultLoading,
    updateAiSearchProductCache,
    withAiClipGlobalSearch,
    withAiClipSearch,
    withAiDetectSearch,
    withAiTextSearch
  };
}

export default useProductsIndexContentAiSearch;
