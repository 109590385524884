import { useState, useCallback, useRef } from 'react';

import { useProductsElasticSearchSuggestInputKeyboard } from '../useProductsElasticSearchSuggestInputKeyboard';
import { useProductsElasticSearchSuggestInputItems } from '../useProductsElasticSearchSuggestInputItems';
import { useAutoFocusRefCb } from '../../../../../../common/hooks/useAutoFocusRefCb';

interface ProductsElasticSearchSuggestInputOptions {
  value?: string;
  autoFocus?: boolean;
  onSubmit: (value: string) => void;
  closePopover: () => void;
  openPopover: () => void;
}
function useProductsElasticSearchSuggestInput({
  value,
  autoFocus,
  onSubmit,
  closePopover,
  openPopover
}: ProductsElasticSearchSuggestInputOptions) {
  const inputRef = useRef<HTMLInputElement>(null);

  const { autoFocusRefCb } = useAutoFocusRefCb();

  const inputRefCb = useCallback<(elem: HTMLInputElement) => void>(
    (elem: HTMLInputElement) => {
      inputRef.current = elem;
      if (autoFocus) {
        autoFocusRefCb(elem);
      }
    },
    [autoFocusRefCb, autoFocus]
  );

  const [localState, setLocalState] = useState<string>(value || '');

  const handleSubmit = useCallback<() => void>(() => {
    onSubmit(localState);
    inputRef.current?.blur();
    closePopover();
  }, [localState, onSubmit, closePopover]);

  const handleSuggestClick = useCallback<(suggestValue: string) => void>(
    (suggestValue) => {
      setLocalState(suggestValue);
      onSubmit(suggestValue);
      inputRef.current?.blur();
      closePopover();
    },
    [onSubmit, closePopover]
  );

  const { popoverItems, makeSuggest } =
    useProductsElasticSearchSuggestInputItems({ initialQuery: value });

  const {
    popoverActiveIndex,
    initializePopoverActiveState,
    handleInputKeyDown
  } = useProductsElasticSearchSuggestInputKeyboard({
    popoverItems,
    setLocalState,
    handleSubmit
  });

  const handleInputFocus = useCallback<() => void>(() => {
    openPopover();
    makeSuggest(localState);
  }, [localState, makeSuggest, openPopover]);

  const handleInputBlur = useCallback<() => void>(() => {
    initializePopoverActiveState();
  }, [initializePopoverActiveState]);

  const handleInputChange = useCallback(
    ({ target: { value: inputValue } }) => {
      setLocalState(inputValue);
      makeSuggest(inputValue);
      initializePopoverActiveState();
      openPopover();
    },
    [makeSuggest, initializePopoverActiveState, openPopover]
  );

  return {
    inputRefCb,
    popoverItems,
    localState,
    popoverActiveIndex,
    handleSubmit,
    handleInputFocus,
    handleInputBlur,
    handleInputChange,
    handleInputKeyDown,
    handleSuggestClick
  };
}

export default useProductsElasticSearchSuggestInput;
