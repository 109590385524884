import {
  FetchProductsFilters,
  ProductClientID,
  ProductsFiltersNavBasePath
} from '../../../../../../products/productsTypes';
import { IconsEnum } from '../../../../../../../assets/icons/types';

import { useThreeDStockProductsCategories } from '../../../../../hooks/useThreeDStockProductsCategories';

import { ThreeDStockContentProductCategoryImage } from '../ThreeDStockContentProductCategoryImage';

import { Icon } from '../../../../../../../helpers/Icon';
import { LoadingGridSkeleton } from '../../../../../../../helpers/LoadingGridSkeleton';
import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { NextPureLinkHelper } from '../../../../../../../helpers/links/NextPureLinkHelper';
import { Translate } from '../../../../../../../helpers/Translate';

import { productsKeys } from '../../../../../../../locales/keys';

import { ProductPath } from '../../../../../../products/ProductPath';

interface ThreeDStockContentProductsProps {
  clientIds?: ProductClientID[];
  filtersNavBasePath?: ProductsFiltersNavBasePath;
  productsFilters: FetchProductsFilters;
  withTitle?: boolean;
}

function ThreeDStockContentProducts({
  clientIds,
  filtersNavBasePath = ProductPath.index(),
  productsFilters,
  withTitle = false
}: ThreeDStockContentProductsProps) {
  const {
    parentCategories,
    productCategoriesWithParents,
    productCategoriesFetched,
    productCategoriesIsPlaceholderData
  } = useThreeDStockProductsCategories({ clientIds, productsFilters });

  return (
    <section className="p-4 max-w-screen-2xl">
      {withTitle && (
        <div className="mb-6">
          <NextPureLinkHelper
            href={ProductPath.index()}
            className="text-2xl font-bold text-gray-900 dark:text-gray-200 hover:underline"
            dataGa="3d-stock-products-title-link"
            i18nText={productsKeys.plural}
          />
        </div>
      )}

      <LoadingGridSkeleton
        className="flex flex-wrap justify-center overflow-x-auto gap-x-2 gap-y-12 py-2 -mx-2 px-1"
        itemClassName="border dark:hover:bg-gray-800 shrink-0 focus-within:ring-base focus-within:ring-offset-0 hover:bg-gray-100 overflow-hidden p-1 relative rounded-2xl w-60"
        loaded={productCategoriesIsPlaceholderData || productCategoriesFetched}
      >
        <div className="flex flex-wrap justify-center overflow-x-auto gap-x-2 gap-y-12 py-2 -mx-2 px-1">
          <div className="w-60 rounded-2xl p-1 shrink-0 overflow-hidden relative">
            <div className="aspect-h-4 aspect-w-3">
              <div className="flex flex-col gap-2 justify-between">
                <div className="flex-1 relative rounded-2xl bg-gray-100 dark:bg-gray-800 hover:bg-gray-200 dark:hover:bg-gray-850 focus-within:ring-base focus-within:ring-offset-0">
                  <div className="flex items-center justify-start gap-4 truncate px-6 py-4 h-full">
                    <Icon
                      className="w-8 h-8 -rotate-90"
                      icon={IconsEnum.COLLECTION_SOLID}
                    />
                    <div className="text-xl font-bold">
                      <Translate id={productsKeys.all} />
                    </div>
                  </div>

                  <NextLinkHelper
                    className="focus-visible:ring-none"
                    dataGa="3d-stock-products-categories-all"
                    href={ProductPath.index()}
                  >
                    <span className="sr-only">
                      <Translate id={productsKeys.all} />
                    </span>
                    <span className="absolute inset-0" aria-hidden="true" />
                  </NextLinkHelper>
                </div>
              </div>
            </div>
          </div>

          {parentCategories.map((category) => (
            <ThreeDStockContentProductCategoryImage
              categories={productCategoriesWithParents}
              category={category}
              filtersNavBasePath={filtersNavBasePath}
              key={category.id}
            />
          ))}
        </div>
      </LoadingGridSkeleton>
    </section>
  );
}

export default ThreeDStockContentProducts;
