import {
  FetchProductsCacheKey,
  FetchProductsElasticSearchFilters,
  FetchElasticSearchProductsMeta,
  FetchProductsLimit,
  ProductBrandName,
  ProductBrandUUID,
  ProductBrandID,
  ProductCreatedAt,
  ProductBlocked,
  ProductFavorite,
  ProductID,
  ProductName,
  ProductNda,
  ProductUUID,
  ProductImageUUID,
  ProductImageFile,
  ProductBrandLocalizedName,
  ProductNanoID,
  ProductRenderTypeID,
  ProductRenderTypeName,
  ProductTagID,
  ProductTagName,
  ProductScore,
  ProductImageID
} from '../../productsTypes';

import { useBffInfiniteIndexQuery } from '../../../common/hooks/base/reactQuery/useBffInfiniteIndexQuery';

import {
  INITIAL_PRODUCTS_ELASTIC_SEARCH_FILTERS,
  INITIAL_PRODUCTS_LIMIT
} from '../../productsConstants';

import { ProductBffUrl } from '../../ProductBffUrl';

export interface FetchElasticSearchProductsProductType {
  score: ProductScore;

  blocked: ProductBlocked;
  favorite: ProductFavorite;
  id: ProductID;
  uuid: ProductUUID;
  nanoId: ProductNanoID;
  name: ProductName;
  nda: ProductNda;
  sku: ProductUUID;
  createdAt: ProductCreatedAt;
  updatedAt: ProductCreatedAt;
  renderTypes: {
    id: ProductRenderTypeID;
    name: ProductRenderTypeName;
  }[];
  image: {
    id: ProductImageID;
    uuid: ProductImageUUID;
    file: ProductImageFile;
  };
  brand: {
    id: ProductBrandID;
    uuid: ProductBrandUUID;
    name: ProductBrandName;
    localizedName: ProductBrandLocalizedName;
  };
  tags: {
    id: ProductTagID;
    localizedName: ProductTagName;
  }[];
}

interface ElasticSearchProductsQueryOptions {
  staleTime?: number;
  enabled?: boolean;
  enabledPlaceholder?: boolean;
  keepPreviousData?: boolean;
}

interface ElasticSearchProductsOptions {
  cacheKey: FetchProductsCacheKey;
  initialFilters?: FetchProductsElasticSearchFilters;
  initialLimit?: FetchProductsLimit;
  options?: ElasticSearchProductsQueryOptions;
}

const scope = 'products';

function useElasticSearchProducts({
  cacheKey,
  initialFilters = INITIAL_PRODUCTS_ELASTIC_SEARCH_FILTERS,
  initialLimit = INITIAL_PRODUCTS_LIMIT,
  options
}: ElasticSearchProductsOptions) {
  const {
    data,
    items,
    itemsError,
    itemsErrorMessage,
    itemsTotalCount,
    meta,
    isFetched,
    isLoading,
    isFetchingNextPage,
    isPlaceholderData,
    currentPage,
    currentLimit,
    currentFilters,
    currentSort,
    hasNextPage,
    updateItemCache,
    clearItemsFilters,
    filterItems,
    changeItemsFilters,
    prefetchItems,
    sortItems,
    limitItems,
    loadMoreItems
  } = useBffInfiniteIndexQuery<
    FetchElasticSearchProductsProductType,
    FetchElasticSearchProductsMeta
  >({
    cacheKey,
    scope,
    url: ProductBffUrl.elasticSearch(),
    initialFilters,
    initialLimit,
    options
  });

  return {
    productsData: data,
    products: items,
    productsError: itemsError,
    productsErrorMessage: itemsErrorMessage,
    productsTotalCount: itemsTotalCount,
    productsMeta: meta,
    productsFetched: isFetched,
    productsLoading: isLoading,
    productsFetchingNextPage: isFetchingNextPage,
    productsIsPlaceholderData: isPlaceholderData,
    productsFilters: currentFilters,
    productsSort: currentSort,
    productsPage: currentPage,
    productsLimit: currentLimit,
    hasNextProductsPage: hasNextPage,
    updateProductCache: updateItemCache,
    filterProducts: filterItems,
    changeProductsFilters: changeItemsFilters,
    clearProductsFilters: clearItemsFilters,
    sortProducts: sortItems,
    loadMoreProducts: loadMoreItems,
    limitProducts: limitItems,
    prefetchProducts: prefetchItems
  };
}

export default useElasticSearchProducts;
