import React, { useCallback, useState, useMemo } from 'react';
import includes from 'lodash/includes';
import without from 'lodash/without';
import isEmpty from 'lodash/isEmpty';
import size from 'lodash/size';
import take from 'lodash/take';
import filter from 'lodash/filter';

import {
  TagCloudWithSearchValueType,
  TagCloudWithSearchItemsType,
  TagCloudWithSearchI18nSearchPlaceholder,
  TagCloudWithSearchSearchValue,
  TagCloudWithSearchOnChange,
  TagCloudWithSearchOnToggleItem
} from './TagCloudWithSearch.types';

import { Translate } from '../Translate';

import { TagCloudWithSearchItem } from './helpers/TagCloudWithSearchItem';
import { TagCloudWithSearchSearch } from './helpers/TagCloudWithSearchSearch';

import { words } from '../../locales/keys';
import { PureButtonHelper } from '../buttons/PureButtonHelper';

export interface TagCloudWithSearchProps {
  value?: TagCloudWithSearchValueType[];
  data?: TagCloudWithSearchItemsType;
  i18nSearchPlaceholder?: TagCloudWithSearchI18nSearchPlaceholder;
  disabled?: boolean;
  onChange: TagCloudWithSearchOnChange;
}

const MINIMIZE_AMOUNT = 50;

function TagCloudWithSearch({
  value = [],
  data = [],
  i18nSearchPlaceholder,
  disabled,
  onChange
}: TagCloudWithSearchProps) {
  const handleToggleItem = useCallback<TagCloudWithSearchOnToggleItem>(
    (changeValue) =>
      onChange(
        includes(value, changeValue)
          ? without(value, changeValue)
          : [...value, changeValue]
      ),
    [onChange, value]
  );

  const [searchValue, setSearchValue] =
    useState<TagCloudWithSearchSearchValue>('');

  const [minimized, setMinimized] = useState<boolean>(true);

  const toggleMinimize = useCallback<() => void>(() => {
    setMinimized((prevState) => !prevState);
  }, [setMinimized]);

  const searchResultData = useMemo<TagCloudWithSearchItemsType>(() => {
    const searchPattern = new RegExp(`${searchValue}`, 'i');
    return searchValue
      ? filter(data, (dataItem) => searchPattern.test(dataItem.label))
      : data;
  }, [searchValue, data]);

  const minimizeEnabled = size(searchResultData) > MINIMIZE_AMOUNT;

  const visibleData = useMemo<TagCloudWithSearchItemsType>(() => {
    return minimizeEnabled && minimized
      ? take(searchResultData, MINIMIZE_AMOUNT)
      : searchResultData;
  }, [minimizeEnabled, minimized, searchResultData]);

  return (
    <div className="mt-3 flex flex-col space-y-2">
      <TagCloudWithSearchSearch
        i18nSearchPlaceholder={i18nSearchPlaceholder}
        searchValue={searchValue}
        onSearch={setSearchValue}
      />

      {!isEmpty(data) && isEmpty(visibleData) ? (
        <div className="p-2 text-center">
          <Translate id={words.noSearchResults} />
        </div>
      ) : null}
      <div className="flex flex-wrap gap-1.5">
        {visibleData.map(({ value: itemValue, label, amount }) => (
          <TagCloudWithSearchItem
            key={itemValue}
            value={itemValue}
            label={label}
            amount={amount}
            checked={includes(value, itemValue)}
            disabled={disabled}
            onToggle={handleToggleItem}
          />
        ))}
        {minimizeEnabled ? (
          <PureButtonHelper
            className="hover:underline leading-6 px-1.5 rounded-sm text-sm whitespace-nowrap"
            onClick={toggleMinimize}
            i18nText={minimized ? words.showMore : words.showLess}
          />
        ) : null}
      </div>
    </div>
  );
}

export default TagCloudWithSearch;
