import React, { memo } from 'react';

import {
  FetchProductsElasticSearchFilters,
  FetchProductsElasticSearchFilterProducts,
  FetchElasticSearchProductsMeta
} from '../../../productsTypes';

import { ProductsElasticSearchCustomTagsFilterBadges } from '../../filterBadges/ProductsElasticSearchCustomTagsFilterBadges';

interface ProductsElasticSearchAppliedFiltersProps {
  productsMeta?: FetchElasticSearchProductsMeta;
  productsFilters: FetchProductsElasticSearchFilters;
  filterProducts: FetchProductsElasticSearchFilterProducts;
}

function ProductsElasticSearchAppliedFilters({
  productsMeta,
  productsFilters,
  filterProducts
}: ProductsElasticSearchAppliedFiltersProps) {
  return (
    <div className="px-4">
      <div className="flex flex-wrap -m-0.5">
        <ProductsElasticSearchCustomTagsFilterBadges
          productsMeta={productsMeta}
          productsFilters={productsFilters}
          filterProducts={filterProducts}
        />
      </div>
    </div>
  );
}

export default memo<ProductsElasticSearchAppliedFiltersProps>(
  ProductsElasticSearchAppliedFilters
);
