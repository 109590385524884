import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';

import { FetchElasticSearchProductsMeta } from '../../../../../productsTypes';

import { useToastNotification } from '../../../../../../../common/hooks/useToastNotification';

import { PureIconButtonHelper } from '../../../../../../../helpers/buttons/PureIconButtonHelper';
import { ContentWithTooltip } from '../../../../../../../helpers/ContentWithTooltip';

import { copyToClipboard } from '../../../../../../../utils/copyToClipboard';

interface ProductsElasticSearchIndexPageBreadcrumbsMetaProps {
  productsMeta?: FetchElasticSearchProductsMeta;
}

function ProductsElasticSearchIndexPageBreadcrumbsMeta({
  productsMeta
}: ProductsElasticSearchIndexPageBreadcrumbsMetaProps) {
  const { showToastNotification } = useToastNotification({
    message: 'Data copied'
  });
  const handleCopyProductUrl = useCallback(() => {
    copyToClipboard(JSON.stringify(productsMeta, null, 2));
    showToastNotification();
  }, [productsMeta, showToastNotification]);

  if (!productsMeta) {
    return;
  }

  return (
    <ContentWithTooltip
      tooltipChildren={JSON.stringify(productsMeta)}
      interactive
    >
      <PureIconButtonHelper
        icon={IconsEnum.INFORMATION_CIRCLE_SOLID}
        onClick={handleCopyProductUrl}
        className="py-2 pl-2 pr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0 text-gray-500"
        iconClassName="h-6 w-6"
      />
    </ContentWithTooltip>
  );
}

export default ProductsElasticSearchIndexPageBreadcrumbsMeta;
