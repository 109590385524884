import React, { useCallback } from 'react';
import cl from 'classnames';

import {
  TagCloudWithSearchValueType,
  TagCloudWithSearchLabelType,
  TagCloudWithSearchAmountType,
  TagCloudWithSearchOnToggleItem
} from '../../TagCloudWithSearch.types';

import { ButtonHelper } from '../../../buttons/ButtonHelper';

interface TagCloudWithSearchItemProps {
  value: TagCloudWithSearchValueType;
  label: TagCloudWithSearchLabelType;
  amount?: TagCloudWithSearchAmountType;
  checked: boolean;
  disabled?: boolean;
  onToggle: TagCloudWithSearchOnToggleItem;
}

function TagCloudWithSearchItem({
  value,
  label,
  amount,
  checked,
  disabled,
  onToggle
}: TagCloudWithSearchItemProps) {
  const handleClick = useCallback<() => void>(() => {
    onToggle(value);
  }, [value, onToggle]);

  return (
    <ButtonHelper
      className={cl(
        'hover:bg-gray-500/40 leading-6 px-1.5 rounded-sm text-sm whitespace-nowrap max-w-full flex',
        checked ? 'bg-gray-500/50' : 'bg-gray-500/25'
      )}
      disabled={disabled}
      onClick={handleClick}
    >
      <span title={label} className="truncate">
        {label}
      </span>
      {amount ? (
        <>
          &nbsp;
          <b>{amount}</b>
        </>
      ) : null}
    </ButtonHelper>
  );
}

export default TagCloudWithSearchItem;
