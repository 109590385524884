import React, { useCallback } from 'react';

import { IconsEnum } from '../../../../../../../assets/icons/types';
import { Icon } from '../../../../../../../helpers/Icon';

import { ProductCategoryID } from '../../../../../productsTypes';

import { FetchProductCategoriesQueryResponse } from '../../../../../queries/fetchProductCategories.query';

import { ButtonHelper } from '../../../../../../../helpers/buttons/ButtonHelper';

interface ProductsElasticSearchCategoriesNavItemProps {
  category: FetchProductCategoriesQueryResponse;
  onSelectCategory: (productCategoryId: ProductCategoryID) => void;
}

function ProductsElasticSearchCategoriesNavItem({
  category,
  onSelectCategory
}: ProductsElasticSearchCategoriesNavItemProps) {
  const handleClick = useCallback<() => void>(() => {
    onSelectCategory(category.id);
  }, [category.id, onSelectCategory]);

  return (
    <div className="-mx-4">
      <div className="flex items-center group relative px-4 py-2 transition-colors ease-in-out duration-150 focus-within:ring-2 focus-within:ring-gray-850 dark:focus-within:ring-gray-100 bg-gray-500 bg-opacity-0 hover:bg-opacity-20">
        <ButtonHelper
          className="focus:ring-0 focus:ring-offset-0 focus:outline-none flex-1 px-2 text-sm text-left"
          onClick={handleClick}
        >
          {category.localizedName}
          <span className="absolute inset-0" aria-hidden="true" />
        </ButtonHelper>
        <Icon
          icon={IconsEnum.CHEVRON_RIGHT}
          className="h-5 w-5 flex-shrink-0"
        />
      </div>
    </div>
  );
}

export default ProductsElasticSearchCategoriesNavItem;
