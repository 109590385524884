import React, { useMemo } from 'react';
import map from 'lodash/map';

import {
  FetchProductsElasticSearchFilters,
  FetchElasticSearchProductsMeta,
  ChangeProductsElasticSearchFiltersFunc
} from '../../../productsTypes';

import { TagCloudWithSearchFilter } from '../../../../../helpers/filters/TagCloudWithSearchFilter';
import { Translate } from '../../../../../helpers/Translate';

import { productsKeys } from '../../../../../locales/keys';
import { TagCloudWithSearchItemsType } from '../../../../../helpers/TagCloudWithSearch';

interface ProductsCustomTagsElasticSearchFilterProps {
  productsFilters: FetchProductsElasticSearchFilters;
  productsMeta?: FetchElasticSearchProductsMeta;
  changeProductsFilters: ChangeProductsElasticSearchFiltersFunc;
}

const name = 'customTagNames' as const;

function ProductsCustomTagsElasticSearchFilter({
  productsFilters,
  productsMeta,
  changeProductsFilters
}: ProductsCustomTagsElasticSearchFilterProps) {
  const filterValue = productsFilters?.[name] || [];

  const data = useMemo<TagCloudWithSearchItemsType>(() => {
    return map(productsMeta?.tags, (tagData) => {
      const customTagName = tagData[0];
      const customTagProductAmount = tagData[1];
      return {
        value: customTagName,
        label: customTagName,
        amount: customTagProductAmount
      };
    });
  }, [productsMeta]);

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={productsKeys.customTags} />
        </h6>
      </div>

      <TagCloudWithSearchFilter
        name={name}
        value={filterValue as string[]}
        data={data}
        i18nSearchPlaceholder={productsKeys.searchCustomTags}
        onChange={changeProductsFilters}
      />
    </div>
  );
}

export default ProductsCustomTagsElasticSearchFilter;
