import React, { useCallback } from 'react';
import compact from 'lodash/compact';

import { ClassName, FetchCacheItemsSources } from '../../../../../types';
import { FetchProductsCacheKeys } from '../../../productsTypes';

import { useClearProductsCache } from '../../../hooks/useClearProductsCache';
import { useCurrentUser } from '../../../../../auth/hooks/useAuth';
import { useShowToastOnErrorChange } from '../../../../../common/hooks/useShowToastOnErrorChange';

import { CLEAR_PRODUCTS_CACHE_QUERY } from '../../../queries/clearProductsCache.query';

import { PureButtonHelper } from '../../../../../helpers/buttons/PureButtonHelper';

import { ProductCache } from '../../../ProductCache';
import { ProductsSetCache } from '../../../../productsSets/ProductsSetCache';
import { productsKeys } from '../../../../../locales/keys';
import { CommonPermissions } from '../../../../common/commonConstants';

interface ClearProductsCacheButtonProps {
  className?: ClassName;
  productsCacheKeys: FetchProductsCacheKeys;
}

function ClearProductsCacheButton({
  className,
  productsCacheKeys
}: ClearProductsCacheButtonProps) {
  const currentUser = useCurrentUser();

  const {
    clearProductsCache,
    clearProductsCacheErrorMessage,
    clearProductsCacheLoading
  } = useClearProductsCache({
    query: CLEAR_PRODUCTS_CACHE_QUERY,
    cacheKeys: compact([
      ProductCache.indexCacheKey(),
      ProductsSetCache.showCacheKey(),
      ...(productsCacheKeys || [])
    ])
  });

  const handleClick = useCallback<() => void>(
    () =>
      clearProductsCache({
        async: true,
        source: FetchCacheItemsSources.ALL
      }),
    [clearProductsCache]
  );

  useShowToastOnErrorChange({ error: clearProductsCacheErrorMessage });

  if (!currentUser.hasPermissions(CommonPermissions.READ_CACHE_QUERY)) {
    return null;
  }

  return (
    <PureButtonHelper
      className={
        className ||
        'py-2 pl-2 pr-2 sm:pl-4 sm:pr-4 mr-2 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-6 focus:ring-base text-white bg-blue-500 hover:bg-blue-600 shadow-sm hover:shadow-md'
      }
      disabled={clearProductsCacheLoading}
      i18nText={productsKeys.clearProductsCache}
      onClick={handleClick}
    />
  );
}

export default ClearProductsCacheButton;
