import React from 'react';

import { IsDisabled } from '../../../../../types';

import {
  FetchProductsFilters,
  ProductClientID,
  ChangeProductsFiltersFunc
} from '../../../productsTypes';

import { useProductsMaterialsFilter } from './hooks/useProductsMaterialsFilter';

import { AlertMessage } from '../../../../../helpers/AlertMessage';
import { CheckboxAlphabeticalGroupFilter } from '../../../../../helpers/filters/CheckboxAlphabeticalGroupFilter';
import { Translate } from '../../../../../helpers/Translate';
import {
  FetchMoreInfiniteButtonHelper,
  FetchMoreInfiniteButtonHelperPlacement
} from '../../../../../helpers/buttons/FetchMoreInfiniteButtonHelper';

import { categoriesKeys, words } from '../../../../../locales/keys';

interface ProductsMaterialsFilterProps {
  clientIds?: ProductClientID[];
  productsFilters: FetchProductsFilters;
  disabled?: IsDisabled;
  changeProductsFilters: ChangeProductsFiltersFunc;
}

const name = 'materialIds' as const;

function ProductsMaterialsFilter({
  clientIds,
  productsFilters,
  disabled,
  changeProductsFilters
}: ProductsMaterialsFilterProps) {
  const filterValue = productsFilters?.[name];

  const {
    productMaterialsFilterSelectedData,
    productsMaterialsFilterData,
    productMaterialsFilterErrorMessage,
    productsMaterialsFilterSelectedErrorMessage,
    productMaterialsFilterFetched,
    productMaterialsFilterIsPlaceholderData,
    productMaterialsFilterFetchingNextPage,
    hasNextProductMaterialsFilterPage,
    productMaterialsFilterSearchValue,
    loadMoreProductMaterialsFilter,
    handleProductMaterialsFilterSearch,
    handleChangeProductsMaterialsFilter
  } = useProductsMaterialsFilter({
    clientIds,
    selectedIds: filterValue,
    name,
    productsFilters,
    changeProductsFilters
  });

  return (
    <div className="p-4">
      <div className="flex mb-3">
        <h6 className="font-light uppercase">
          <Translate id={categoriesKeys.materials.plural} />
        </h6>
      </div>
      <AlertMessage
        message={
          productMaterialsFilterErrorMessage ||
          productsMaterialsFilterSelectedErrorMessage
        }
      />

      <CheckboxAlphabeticalGroupFilter
        name={name}
        dataGa="products-materials-filter"
        disabled={disabled}
        value={filterValue as string[]}
        sortedItems={productsMaterialsFilterData}
        selectedItems={productMaterialsFilterSelectedData}
        itemsFetched={
          productMaterialsFilterIsPlaceholderData ||
          productMaterialsFilterFetched
        }
        i18nSearchPlaceholder={categoriesKeys.materials.search}
        searchValue={productMaterialsFilterSearchValue}
        fetchMoreButton={
          <FetchMoreInfiniteButtonHelper
            dataGa="products-materials-filter"
            errorMessage={productMaterialsFilterErrorMessage}
            isLoading={productMaterialsFilterFetchingNextPage}
            hasNextPage={hasNextProductMaterialsFilterPage}
            i18nText={words.showMore}
            wrapperClassName="relative p-2 text-center"
            className="py-1.5 pl-3 pr-3 rounded-md inline-flex items-center whitespace-nowrap text-sm font-medium leading-5 focus:ring-base hover:text-gray-950 dark:hover:text-white hover:bg-gray-200 dark:hover:bg-gray-700 focus:ring-offset-0"
            placement={FetchMoreInfiniteButtonHelperPlacement.BOTTOM}
            onFetchMore={loadMoreProductMaterialsFilter}
          />
        }
        onSearch={handleProductMaterialsFilterSearch}
        onChange={handleChangeProductsMaterialsFilter}
      />
    </div>
  );
}

export default ProductsMaterialsFilter;
