import { ProductsFiltersNavBasePath } from '../../../../../../products/productsTypes';
import {
  ThreeDStockIndexPageProductsCategoriesNavCategories,
  ThreeDStockIndexPageProductsCategoriesNavCategory
} from '../../../../menus/ThreeDStockIndexPageProductsCategoriesNav/ThreeDStockIndexPageProductsCategoriesNav.types';

import {
  FileAttachmentItemImageVersions,
  ImageHelper
} from '../../../../../../../helpers/ImageHelper';
import { NextLinkHelper } from '../../../../../../../helpers/links/NextLinkHelper';
import { getChangeProductsFiltersCategoriesData } from '../../../../../../products/utils/getChangeProductsFiltersCategoriesData';

import { ProductPath } from '../../../../../../products/ProductPath';

interface ThreeDStockContentProductCategoryImageProps {
  categories: ThreeDStockIndexPageProductsCategoriesNavCategories;
  category: ThreeDStockIndexPageProductsCategoriesNavCategory;
  filtersNavBasePath: ProductsFiltersNavBasePath;
}

function ThreeDStockContentProductCategoryImage({
  categories,
  category,
  filtersNavBasePath
}: ThreeDStockContentProductCategoryImageProps) {
  return (
    <div className="dark:hover:bg-gray-800 shrink-0 focus-within:ring-base focus-within:ring-offset-0 hover:bg-gray-100 overflow-hidden p-1 relative rounded-2xl w-60">
      <div className="aspect-w-3 aspect-h-4">
        <ImageHelper
          src={category.image?.file}
          alt={category.localizedName}
          className="rounded-2xl object-cover bg-gray-100 dark:bg-gray-800"
          // src="https://test-static.archivizer.com/uploads/images/image/file/2RVZ2HB8/2878233.5ed3a9292700f_2.jpg"
          version={FileAttachmentItemImageVersions.BigThumb538x435}
        />
      </div>

      <div className="text-center font-medium truncate p-3">
        <NextLinkHelper
          href={ProductPath.indexFiltersCustomBaseQuery(
            filtersNavBasePath,
            getChangeProductsFiltersCategoriesData({
              productCategory: category,
              categories
            }).changeFilters
          )}
          className="focus-visible:ring-none"
          dataGa={`3d-stock-products-categories-${
            category.nameEn || category.localizedName
          }`}
        >
          {category.localizedName}
          <span className="absolute inset-0" aria-hidden="true" />
        </NextLinkHelper>
      </div>
    </div>
  );
}

export default ThreeDStockContentProductCategoryImage;
